<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Barcazas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Embarcaciones de carga</li>
                  <li class="breadcrumb-item active">Barcazas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!--  <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_conductor_export"
                        v-if="$store.getters.can('admin.conductores.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button> -->
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('admin.barcazas.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>ID</th>
                      <th>
                        Placa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Bandera</th>
                      <th>
                        Líneas Negocio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="linea_negocio"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.lineas_negocio"
                          :filterable="true"
                          multiple
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.selct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="barcaza in barcazas.data" :key="barcaza.id">
                      <td>
                        <!-- Imagenes -->
                      </td>
                      <td>
                        {{ barcaza.id }}
                      </td>
                      <td>
                        {{ barcaza.nombre }}
                      </td>
                      <td>{{ barcaza.pais.nombre }}</td>
                      <td>
                        <span class="badge bg-navy">
                          {{ barcaza.lineas_negocio.nombre }}
                        </span>
                      </td>
                      <td>
                        {{ barcaza.empresa.razon_social }}
                      </td>
                      <td style="width: 85px" align="right">
                        <div class="row">
                          <div class="col-md-6">
                            <span
                              class="badge"
                              data-toggle="modal"
                              data-target="#modal-form-estado"
                              style="cursor: pointer"
                              v-bind:class="[
                                barcaza.estado == 1
                                  ? 'badge-success'
                                  : 'badge-danger',
                              ]"
                              @click="llenarModal(barcaza.id, barcaza.estado)"
                            >
                              {{ barcaza.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                            </span>
                          </div>
                          <div class="col-md-6">
                            <i
                              class="fas fa-eye"
                              data-toggle="modal"
                              data-target="#modal-historial-estado"
                              style="cursor: pointer"
                              v-if="$store.getters.can('admin.vehiculos.show')"
                              @click="
                                llenarModalHistorial(
                                  'App\\Barcazas',
                                  barcaza.id
                                )
                              "
                            ></i>
                          </div>
                        </div>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <div
                            v-if="
                              $store.getters.can(
                                'admin.vehiculos.editarTransportadora'
                              ) && !admin
                            "
                          >
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              @click="edit(barcaza, 3)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                          <div v-else>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('admin.vehiculos.show')"
                              @click="edit(barcaza, 1)"
                            >
                              <i class="fas fa-eye"></i>
                            </button>
                          </div>
                          <div v-if="empresa.length > 0">
                            <div v-if="barcaza.empresa_id">
                              <div>
                                <button
                                  type="button"
                                  class="btn btn-sm bg-navy"
                                  v-if="
                                    $store.getters.can(
                                      'admin.vehiculos.edit'
                                    ) && empresa[0].id == barcaza.empresa_id
                                  "
                                  @click="edit(barcaza, 2)"
                                >
                                  <i class="fas fa-edit"></i>
                                </button>
                              </div>
                            </div>
                            <div v-else>
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                v-if="
                                  $store.getters.can('admin.vehiculos.edit')
                                "
                                @click="edit(barcaza, 2)"
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </div>
                          </div>
                          <div v-else>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('admin.vehiculos.edit')"
                              @click="edit(barcaza, 2)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="barcazas.total">
                  <p>
                    Mostrando del <b>{{ barcazas.from }}</b> al
                    <b>{{ barcazas.to }}</b> de un total:
                    <b>{{ barcazas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="barcazas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <BarcazaEstado ref="BarcazaEstado" />
        <HistorialEstado ref="HistorialEstado" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import BarcazaEstado from "./BarcazaEstado.vue";
import HistorialEstado from "../historialEstado/HistorialEstado";

import vSelect from "vue-select";

export default {
  name: "Barcazas",
  components: {
    pagination,
    BarcazaEstado,
    HistorialEstado,
    vSelect,
  },
  data() {
    return {
      linea_negocio: [],
      empresas: [],
      user: null,
      admin: null,
      empresa: this.$store.getters.getUser.empresas,
      tipo_usuario_cambia_estado: null,
      filtros: {
        tipo_documento: null,
        numero_documento: null,
        empresa_id: null,
        estado: null,
      },
      barcazas: {},
      listasForms: {
        tipo_documentos: [],
        estados: [],
        lineas_negocio: [],
        selct_empresa: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.filtros.lineas_negocio = [];
      this.filtros.empresa_id = null;
      this.linea_negocio.forEach((linea) => {
        this.filtros.lineas_negocio.push(linea.id);
      });
      if (this.listasForms.selct_empresa) {
        this.filtros.empresa_id = this.listasForms.selct_empresa.id;
      }
      axios
        .get("/api/admin/barcazas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          console.log(response.data);

          this.barcazas = response.data;
          this.cargando = false;
        });

      if (this.$store.state.user) {
        const administrador = this.$store.state.user.roles.find(
          (administrador) => administrador.id === 1
        );
        if (administrador) {
          this.admin = administrador.id;
        }
      }
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getTiposIdentificacion() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_documentos = response.data;
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(idConductor) {
      this.$swal({
        title: "Esta seguro de eliminar este Conductor?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, ElimInar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/conductores/" + idConductor).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el Conductor exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    llenarModal(id, estado) {
      if (this.$store.getters.can("admin.vehiculos.editStatus")) {
        this.$refs.BarcazaEstado.llenar_modal(id, estado);
      }
    },

    llenarModalHistorial(entidad, entidad_id) {
      if (this.$store.getters.can("admin.vehiculos.show")) {
        this.$refs.HistorialEstado.llenar_modal(entidad, entidad_id);
      }
    },

    create() {
      return this.$router.push({
        name: "/Admin/ConductorForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(barcaza, opcion) {
      return this.$router.push({
        name: "/Admin/BarcazaForm",
        params: {
          accion: "Editar",
          data_edit: barcaza,
          id: barcaza.id,
          opcion: opcion,
        },
      });
    },

    pivotTable() {
      return this.$router.push({
        name: "/Admin/ConductorPivotTable",
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTiposIdentificacion();
    this.getMsg();
    this.getLineasNegocio();
  },
};
</script>
