<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tarifarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Tarifarios</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-md-3 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Tarifarios</h5>
                </div>
              </div>

              <!-- pestañas -->
              <div class="modal-body p-0">
                <ul id="myTab" role="tablist" class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      data-toggle="tab"
                      href="#Tarifario"
                      class="nav-link active"
                      >Tarifario</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="
                      form.id &&
                        $store.getters.can(
                          'hidrocarburos.fluvialesTarifarios.showTarifas'
                        )
                    "
                  >
                    <a
                      data-toggle="tab"
                      href="#TarifasFluviales"
                      class="nav-link"
                      >Tarifas Fluviales
                    </a>
                  </li>
                </ul>
                <div id="myTabContent" class="tab-content p-3">
                  <div id="Tarifario" class="tab-pane fade active show">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label>Nombre</label>
                        <input
                          type="text"
                          class="form-control form-control-sm shadow-sm"
                          v-model="form.nombre"
                          :class="
                            $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          :disabled="estado_tarifario == 2"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label> Tipo Tarifario</label>
                        <select
                          class="form-control form-control-sm shadow-sm"
                          v-model="form.tipo_tarifario"
                          :class="
                            $v.form.tipo_tarifario.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          :disabled="estado_tarifario == 2"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_tarifario in listasForms.tipo_tarifarios"
                            :key="tipo_tarifario.numeracion"
                            :value="tipo_tarifario.numeracion"
                          >
                            {{ tipo_tarifario.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-2">
                        <label>Estado</label>
                        <select
                          class="form-control form-control-sm shadow-sm"
                          v-model="form.estado"
                          :class="
                            $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          :disabled="
                            accion != 'Editar' ||
                              estado_tarifario == 2 ||
                              !$store.getters.can('hidrocarburos.fluvialesTarifarios.edit')
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="observaciones">Observaciones</label>
                        <textarea
                          vtype="date"
                          class="form-control shadow-sm"
                          v-model="form.observaciones"
                          rows="3"
                          :disabled="estado_tarifario == 2"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div id="TarifasFluviales" class="tab-pane">
                    <TarifariosTarifasFluviales
                      ref="TarifariosTarifasFluviales"
                      v-if="
                        form.id &&
                          $store.getters.can(
                            'hidrocarburos.fluvialesTarifarios.showTarifas'
                          )
                      "
                    ></TarifariosTarifasFluviales>
                  </div>
                </div>
              </div>
              <!-- fin pestañas -->

              <div class="card-footer">
                <div class="btn-group row shadow">
                  <button class="btn bg-gradient-secondary pl-3 pr-3" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                  <button
                    class="btn bg-gradient-primary"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      ($store.getters.can('hidrocarburos.fluvialesTarifarios.create') ||
                        $store.getters.can('hidrocarburos.fluvialesTarifarios.edit')) &&
                        this.estado_tarifario != 2
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
import TarifariosTarifasFluviales from "./TarifariosTarifasFluviales";

export default {
  name: "TarifariosFluvialesForm",
  components: {
    Loading,
    TarifariosTarifasFluviales,
  },

  data() {
    return {
      cargando: false,
      accion: "",
      metodo: "",
      datos_tarifario: null,
      estado_tarifario: null,
      form: {
        id: null,
        nombre: null,
        tipo_tarifario: null,
        observaciones: null,
        estado: 1,
      },
      listasForms: {
        estados: [],
        tipo_tarifarios: [],
      },
      tarifas_transportes: [],
    };
  },

  validations: {
    form: {
      nombre: {
        required,
      },
      tipo_tarifario: {
        required,
      },
      estado: {
        required,
      },
    },
  },

  methods: {
    getIndex() {
      // Se establece la accion del módulo
      this.accion = this.$route.params.accion;
      if (this.accion == "Editar") {
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }

      // Se buscan los datos del tarifario
      if (this.$route.params.id) {
        this.cargando = true;
        axios
          .get(
            "/api/hidrocarburos/TarifariosFluviales/show/" +
              this.$route.params.id
          )
          .then((response) => {
            this.cargando = false;
            this.datos_tarifario = response.data;
            this.estado_tarifario = response.data.estado;
            this.getForm();
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    getForm() {
      this.form = {
        id: this.datos_tarifario.id,
        nombre: this.datos_tarifario.nombre,
        tipo_tarifario: this.datos_tarifario.tipo_tarifario,
        observaciones: this.datos_tarifario.observaciones,
        estado: this.datos_tarifario.estado,
      };
    },

    // Funciones para llenado de las listas
    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTiposTarifarios() {
      axios.get("/api/lista/216").then((response) => {
        this.listasForms.tipo_tarifarios = response.data;
      });
    },

    // Funciones para las acciones de los formularios
    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/TarifariosFluviales",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            if (!response.data.msg_error) {
              this.$route.params.accion = "Editar";
              this.$route.params.id = response.data.id;
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "El tarifario se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.form.nombre = null;
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Hidrocarburos/TarifariosFluviales");
    },
  },
  mounted() {
    this.getEstados();
    this.getTiposTarifarios();
    this.getIndex();
  },
};
</script>
