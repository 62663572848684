<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Cierres Operativos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Cierres Operativos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div :class="form.id ? 'col-md-9' : 'col-md-12'">
                <div class="card shadow">
                  <div class="card-body">
                    <div class="row">
                      <!-- Fecha Solicitud -->
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Fecha Solicitud</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="form.fecha_solicitud"
                            :class="
                              $v.form.fecha_solicitud.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :max="hoy"
                            :disabled="accion == 'Editar'"
                          />
                        </div>
                      </div>
                      <!-- Sitio -->
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Sitio </label>
                          <v-select
                            class="form-control form-control-sm p-0"
                            label="nombre"
                            placeholder="Sitio"
                            v-model="sitio"
                            :options="listasForms.sitios"
                            @input="selectSitio()"
                            :class="
                              $v.form.sitio_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="accion == 'Editar'"
                          >
                          </v-select>
                        </div>
                      </div>
                      <!-- Tipo Operación -->
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Tipo Operación</label>
                          <select
                            v-model="form.tipo_operacion"
                            class="form-control form-control-sm"
                            :class="
                              $v.form.tipo_operacion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="accion == 'Editar'"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipo in listasForms.tipos_sitio"
                              :key="tipo.numeracion"
                              :value="tipo.numeracion"
                            >
                              {{ tipo.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- Estado -->
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Estado </label>
                          <select
                            v-model="form.estado"
                            class="form-control form-control-sm"
                            :class="
                              $v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="!form.cierre_campo_user_id"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- Cierre Operativo -->
                      <div class="col-md-2 text-center" v-if="form.id">
                        <label for="check_cierre">Cierre Operativo</label>
                        <input
                          v-model="form.check_cierre"
                          id="check_cierre"
                          type="checkbox"
                          class="form-control form-control-sm"
                          @change="cierreOperativo()"
                          :checked="form.check_cierre == true"
                          :disabled="
                            form.tipo_operacion == 1
                              ? guiasCargues.some(
                                  (cargue) => !cargue.confirmacion_user_id
                                )
                              : guiasDescargues.some(
                                  (descargue) =>
                                    descargue.confirmacion_user_id == null
                                ) || form.estado == 2
                          "
                        />
                        <span
                          class="badge bg-warning"
                          v-if="
                            form.tipo_operacion == 1
                              ? guiasCargues.some(
                                  (cargue) =>
                                    cargue.confirmacion_user_id == null
                                )
                              : guiasDescargues.some(
                                  (descargue) =>
                                    descargue.confirmacion_user_id == null
                                )
                          "
                          >Sin Verificar Guías</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row justify-content-center">
                      <button
                        type="button"
                        class="btn bg-gradient-primary shadow"
                        @click="save()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.operativoCierre.save'
                          ) && !$v.form.$invalid
                        "
                        :disabled="estado == 2"
                      >
                        <i class="fas fa-paper-plane"></i>
                        Guardar
                      </button>
                      <router-link to="/Hidrocarburos/CierresOperativos">
                        <button
                          type="button"
                          class="btn bg-gradient-secondary shadow ml-2"
                        >
                          <i class="fas fa-reply"></i>
                          Volver
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!-- Card Totales-->
                <div class="row justify-content-center" v-if="form.id">
                  <!-- Card GOV-->
                  <div class="col-lg-3 col-6" v-if="form.tipo_operacion == 1">
                    <div class="small-box alert-default-success shadow">
                      <div class="inner">
                        <h4 v-if="totGuiasCargue.totGov">
                          <strong>{{
                            form.tipo_operacion == 1 ? totGuiasCargue.totGov : 0
                          }}</strong>
                        </h4>
                        <p>GOV</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-levels"></i>
                      </div>
                      <a class="small-box-footer bg-success"
                        >Total Cargue <i class="fas fa-arrow-circle-right"></i
                      ></a>
                    </div>
                  </div>
                  <!-- Card GSV-->
                  <div class="col-lg-3 col-6">
                    <div class="small-box alert-default-info shadow">
                      <div class="inner">
                        <h4
                          v-if="
                            totGuiasCargue.totGsv || totGuiasDescargue.totGsv
                          "
                        >
                          <strong>{{
                            form.tipo_operacion == 1
                              ? totGuiasCargue.totGsv
                              : totGuiasDescargue.totGsv
                          }}</strong>
                        </h4>
                        <p>GSV</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-levels"></i>
                      </div>
                      <a class="small-box-footer bg-info"
                        >Total
                        {{ form.tipo_operacion == 1 ? "Cargue" : "Descargue" }}
                        <i class="fas fa-arrow-circle-right"></i
                      ></a>
                    </div>
                  </div>
                  <!-- Card NSV-->
                  <div class="col-lg-3 col-6">
                    <div class="small-box alert-default-primary shadow">
                      <div class="inner">
                        <h4
                          v-if="
                            totGuiasCargue.totNsv || totGuiasDescargue.totNsv
                          "
                        >
                          <strong>{{
                            form.tipo_operacion == 1
                              ? totGuiasCargue.totNsv
                              : totGuiasDescargue.totNsv
                          }}</strong>
                        </h4>
                        <p>NSV</p>
                      </div>
                      <div class="icon">
                        <i class="ion ion-podium"></i>
                      </div>
                      <a class="small-box-footer bg-primary"
                        >Total
                        {{ form.tipo_operacion == 1 ? "Cargue" : "Descargue" }}
                        <i class="fas fa-arrow-circle-right"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Datos Usuario -->
              <div class="col-md-3" v-if="form.id">
                <div class="card card-outline card-primary col-md-12 shadow">
                  <div class="card-header pt-2 pb-2">
                    <h3 class="card-title">
                      <h6>
                        <i class="fas fa-users"></i>
                        Datos de Usuario
                      </h6>
                    </h3>
                  </div>
                  <div class="pt-2 pb-2 mt-2">
                    <div class="form-group">
                      <label for="user">Usuario</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="user"
                        v-model="form.user.name"
                        disabled
                      />
                    </div>
                    <div class="form-group">
                      <label for="correo">Correo</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="correo"
                        v-model="form.user.email"
                        disabled
                      />
                    </div>
                    <div class="form-group">
                      <label for="celular">Celular</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="celular"
                        v-model="form.user.cel"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Detalle Guías Cargue / Descargue -->
            <div v-if="form.id">
              <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" v-if="form.tipo_operacion == 1">
                      <a
                        class="nav-link"
                        :class="form.tipo_operacion == 1 ? 'active' : ''"
                        id="tab-Cargue"
                        data-toggle="tab"
                        href="#Cargue"
                        >Cargue</a
                      >
                    </li>
                    <li class="nav-item" v-if="form.tipo_operacion == 2">
                      <a
                        class="nav-link"
                        :class="form.tipo_operacion == 2 ? 'active' : ''"
                        id="tab-Descargue"
                        data-toggle="tab"
                        href="#Descargue"
                        >Descargue</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="tab-content" id="myTabContent">
                  <!-- Guías Cargue -->
                  <div
                    v-if="form.tipo_operacion == 1"
                    class="tab-pane fade"
                    :class="form.tipo_operacion == 1 ? 'active show' : ''"
                    id="Cargue"
                  >
                    <div class="card-body table-responsive p-0">
                      <div class="row">
                        <div class="col-md-12 mt-3">
                          <table
                            class="table table-bordered table-sm text-nowrap"
                          >
                            <thead class="table-dark">
                              <tr class="text-center ">
                                <th></th>
                                <th>Operación</th>
                                <th>Viaje</th>
                                <th>Vehículo</th>
                                <th>Remolque</th>
                                <th>Conductor</th>
                                <th>Empresa</th>
                                <th>N° Guía</th>
                                <th>Fecha Cargue</th>
                                <th>Fecha Expedición</th>
                                <th>Producto</th>
                                <th>Producto Líquido</th>
                                <th>Ruta</th>
                                <th>Sitio Destino</th>
                                <th>Api</th>
                                <th>Azufre</th>
                                <th>Bsw</th>
                                <th>Gov</th>
                                <th>Gsv</th>
                                <th>Nsv</th>
                                <th>Acciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(guiaCargue, index) in guiasCargues"
                                :key="index"
                                :class="{
                                  'alert-default-success':
                                    checkCargue[index] ||
                                    guiaCargue.confirmacion_user_id,
                                }"
                              >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                  <span
                                    class="badge bg-success"
                                    v-if="
                                      guiaCargue.guia_type == 'App\\DetGuia'
                                    "
                                  >
                                    Ventas
                                  </span>
                                  <span
                                    class="badge"
                                    :class="
                                      guiaCargue.nOperacion ==
                                      'Ventas A Terceros'
                                        ? 'bg-navy'
                                        : 'bg-info'
                                    "
                                    v-else
                                  >
                                    {{ guiaCargue.nOperacion }}
                                  </span>
                                </td>
                                <td class="text-center">
                                  {{
                                    guiaCargue.numero_viaje
                                      ? guiaCargue.numero_viaje
                                      : guiaCargue.operacion_id
                                  }}
                                </td>
                                <td class="text-center">
                                  {{
                                    guiaCargue.placa
                                      ? guiaCargue.placa
                                      : guiaCargue.vehiculo
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.remolque }}
                                </td>
                                <td class="text-center">
                                  {{
                                    guiaCargue.nombre_conductor
                                      ? guiaCargue.nombre_conductor
                                      : guiaCargue.conductor
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.transportadora }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.numero_guia }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.fecha_cargue }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.fecha_expedicion }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.producto }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.producto_liquido }}
                                </td>
                                <td class="text-center">
                                  {{
                                    guiaCargue.ruta
                                      ? guiaCargue.ruta
                                      : guiaCargue.sitio_origen +
                                        " - " +
                                        guiaCargue.sitio_destino
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.sitio_destino }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.api }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.azufre }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.bsw }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.gov }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.gsv }}
                                </td>
                                <td class="text-center">
                                  {{ guiaCargue.nsv }}
                                </td>
                                <td class="text-center">
                                  <input
                                    type="checkbox"
                                    v-model="checkCargue[index]"
                                    @change="
                                      saveVerificacion(
                                        checkCargue[index],
                                        guiaCargue,
                                        index,
                                        'Cargue'
                                      )
                                    "
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.operativoCierre.verificar'
                                      )
                                    "
                                    :disabled="estado == 2 || form.check_cierre"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Guías Descargue  -->
                  <div
                    v-if="form.tipo_operacion == 2"
                    class="tab-pane fade"
                    :class="form.tipo_operacion == 2 ? 'active show' : ''"
                    id="Descargue"
                  >
                    <div class="card-body table-responsive p-0">
                      <div class="row">
                        <div class="col-md-12 mt-3">
                          <table
                            class="table table-bordered table-sm text-nowrap"
                          >
                            <thead class="table-dark">
                              <tr class="text-center ">
                                <th></th>
                                <th>Operación</th>
                                <th>Viaje</th>
                                <th>Vehículo</th>
                                <th>Remolque</th>
                                <th>Conductor</th>
                                <th>Empresa</th>
                                <th>N° Guía</th>
                                <th>Fecha Cargue</th>
                                <th>Fecha Descargue</th>
                                <th>Fecha Expedición</th>
                                <th>Producto</th>
                                <th>Producto Líquido</th>
                                <th>Ruta</th>
                                <th>Sitio Destino</th>
                                <th>Api</th>
                                <th>Gsv</th>
                                <th>Nsv</th>
                                <th>Acciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(guiaDescargue,
                                indice) in guiasDescargues"
                                :key="indice"
                                :class="{
                                  'alert-default-primary':
                                    checkDescargue[indice] ||
                                    guiaDescargue.confirmacion_user_id,
                                }"
                              >
                                <td class="text-center">{{ indice + 1 }}</td>
                                <td class="text-center">
                                  <span
                                    class="badge bg-success"
                                    v-if="
                                      guiaDescargue.guia_type == 'App\\DetGuia'
                                    "
                                  >
                                    Ventas
                                  </span>
                                  <span class="badge bg-navy" v-else>
                                    Compras Sin Transporte
                                  </span>
                                </td>
                                <td class="text-center">
                                  {{
                                    guiaDescargue.numero_viaje
                                      ? guiaDescargue.numero_viaje
                                      : guiaDescargue.operacion_id
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.placa }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.remolque }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.nombre_conductor }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.transportadora }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.numero_guia }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.fecha_cargue }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.fecha_hora_descargue }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.fecha_expedicion }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.producto }}
                                </td>
                                <td class="text-center">
                                  {{
                                    guiaDescargue.producto_liquido
                                      ? guiaDescargue.producto_liquido
                                      : ""
                                  }}
                                </td>
                                <td class="text-center">
                                  {{
                                    guiaDescargue.ruta
                                      ? guiaDescargue.ruta
                                      : guiaDescargue.sitio_origen +
                                        " - " +
                                        guiaDescargue.sitio_destino
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.sitio_destino }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.api }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.gsv }}
                                </td>
                                <td class="text-center">
                                  {{ guiaDescargue.nsv }}
                                </td>
                                <td class="text-center">
                                  <input
                                    type="checkbox"
                                    v-model="checkDescargue[indice]"
                                    @change="
                                      saveVerificacion(
                                        checkDescargue[indice],
                                        guiaDescargue,
                                        indice,
                                        'Descargue'
                                      )
                                    "
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.operativoCierre.verificar'
                                      )
                                    "
                                    :disabled="estado == 2 || form.check_cierre"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";

export default {
  name: "CierreOperativoForm",

  components: {
    Loading,
    vSelect,
  },

  data() {
    return {
      cargando: false,
      accion: null,
      metodo: null,
      estado: null,
      sitio: {},
      guiasCargues: [],
      guiasDescargues: [],
      checkCargue: [],
      checkDescargue: [],
      totGuiasCargue: {},
      totGuiasDescargue: {},
      bitacoras: [],
      hoy: moment().format("YYYY-MM-DD"),
      form: {
        tipo_operacion: null,
        sitio_id: null,
        user_id: null,
        fecha_solicitud: null,
        fecha_cierre_campo: null,
        check_cierre: false,
        estado: null,
      },
      listasForms: {
        sitios: [],
        tipos_sitio: [],
        estados: [],
      },
    };
  },

  validations: {
    form: {
      sitio_id: {
        required,
      },
      tipo_operacion: {
        required,
      },
      fecha_solicitud: {
        required,
      },
      estado: {
        required,
      },
    },
  },

  methods: {
    async init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      this.form.user_id = this.$store.getters.getUser.id;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        this.form = this.$route.params.data_edit;
        this.form.check_cierre = this.form.fecha_cierre_campo ? true : false;
        this.sitio = this.form.sitio;
        await this.getDetalle();
        //Si es Cargue
        if (this.form.tipo_operacion == 1) {
          this.guiasCargues.forEach((guia, index) => {
            this.$set(
              this.checkCargue,
              index,
              guia.confirmacion_user_id ? true : false
            );
          });
        } else {
          //Si es Descargue
          this.guiasDescargues.forEach((guiaD, indice) => {
            // Llenar checkDescargue en las mismas posiciones de guiasDescargues
            this.$set(
              this.checkDescargue,
              indice,
              guiaD.confirmacion_user_id ? true : false
            );
          });
        }
        this.estado = this.$route.params.data_edit.estado;
        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
        this.estado = 1;
        this.cargando = false;
      }
      this.cargando = false;
    },

    async getDetalle() {
      this.cargando = true;
      await axios
        .get(
          "/api/hidrocarburos/cierresOperativos/showDetCierre/" + this.form.id
        )
        .then((response) => {
          this.guiasCargues = response.data.guiasCargues
            ? response.data.guiasCargues
            : [];

          this.guiasDescargues = response.data.guiasDescargue
            ? response.data.guiasDescargue
            : [];

          this.totGuiasCargue = response.data.guiasCargueTot
            ? response.data.guiasCargueTot
            : {};

          this.totGuiasDescargue = response.data.guiasDescargueTot
            ? response.data.guiasDescargueTot
            : {};
          this.cargando = false;
        });
      this.cargando = false;
    },

    async getSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      await axios
        .get(url)
        .then(function(response) {
          me.listasForms.sitios = response.data;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getEstados() {
      await axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getTipoSitio() {
      await axios.get("/api/lista/18").then((response) => {
        let listaT = [];
        response.data.forEach((lista) => {
          if (lista.numeracion != 3) {
            listaT.push({
              cod_alterno: lista.cod_alterno,
              descripcion: lista.descripcion,
              estado: lista.estado,
              id: lista.id,
              lista_id: lista.lista_id,
              numeracion: lista.numeracion,
            });
          }
        });
        this.listasForms.tipos_sitio = listaT;
      });
    },

    selectSitio() {
      this.form.sitio_id = null;
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
      }
    },

    async save() {
      this.cargando = true;
      await axios({
        method: this.metodo,
        url: "/api/hidrocarburos/cierresOperativos",
        data: this.form,
      })
        .then(async (response) => {
          this.cargando = false;
          if (response.data.status == 0) {
            await this.$swal({
              icon: "error",
              title: response.data.msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.$route.params.accion = "Editar";
            this.$route.params.data_edit = response.data;
            await this.init();
            await this.$swal({
              icon: "success",
              title: "El cierre operativo se guardó correctamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    async saveVerificacion(check, guia, index, tipo) {
      if (check) {
        await this.$swal({
          title: "Esta seguro de verificar este registro?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Verificar!",
        }).then(async (result) => {
          if (result.value) {
            this.cargando = true;
            let data = {
              det_cierre_id: guia.det_cierre_id,
              cierre_id: guia.cierre_operativo_id,
              accion: 1,
            };
            await axios
              .post(
                "/api/hidrocarburos/cierresOperativos/saveVerificacion",
                data
              )
              .then(async (response) => {
                this.$route.params.data_edit = response.data.cierre;
                await this.init();
                this.$swal({
                  icon: "success",
                  title: response.data.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.cargando = false;
              });
          } else {
            if (tipo == "Cargue") {
              this.$set(this.checkCargue, index, false);
            } else {
              this.$set(this.checkDescargue, index, false);
            }
          }
        });
      } else {
        await this.$swal({
          title: "Esta seguro de quitar el verificado de este registro?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Acepto!",
        }).then(async (result) => {
          if (result.value) {
            this.cargando = true;
            let data = {
              det_cierre_id: guia.det_cierre_id,
              cierre_id: guia.cierre_operativo_id,
              accion: 2,
            };
            await axios
              .post(
                "/api/hidrocarburos/cierresOperativos/saveVerificacion",
                data
              )
              .then(async (response) => {
                this.$route.params.data_edit = response.data.cierre;
                await this.init();
                this.$swal({
                  icon: "success",
                  title: response.data.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.cargando = false;
              });
          } else {
            await this.init();
          }
        });
      }
    },

    async cierreOperativo() {
      this.cargando = true;
      if (this.form.check_cierre) {
        await this.getNovedad();
        if (this.bitacoras.length > 0) {
          this.cargando = false;
          const cierre = {
            sitio: this.sitio,
          };

          let novedades = this.bitacoras.map(
            (item, index) =>
              `${index + 1}. ${item.nTipoNovedad} - Estado: ${item.nEstado}.`
          );

          if (this.bitacoras.some((item) => item.estado === 1)) {
            novedades.push(
              `Según las novedades anteriores, todavía se encuentran novedades por cerrar, ¿Desea cerrarlas en este momento?`
            );
          }

          novedades = novedades.join("\n");
          let alert = await this.mostrarAlert(
            `Para realizar el cierre operativo, se presentaron las siguientes novedades para el sitio ${this.form.sitio.nombre}: `,
            `${novedades}`,
            "warning",
            "Si, Cerrar Novedad!",
            "No, Realizar Cierre Operativo"
          );

          if (alert.value) {
            this.cargando = true;
            await this.$store.dispatch("getMenus", 1);
            await this.$store.dispatch(
              "getRoutes",
              this.$store.getters.getMenus
            );
            await this.$store.dispatch("setRoutes");
            await this.permisoLineaNegocio(1);
            this.cargando = false;
            this.$router.push({
              name: "/Admin/BitacoraNovedades",
              params: {
                accion: "Cierre",
                data_edit: cierre,
              },
            });
          } else {
            await this.procesarCierre(1);
          }
        } else {
          this.cargando = false;
          let alert = await this.mostrarAlert(
            `Por favor revisar si tiene una novedad relevante para el sitio ${this.form.sitio.nombre} en la Fecha: ${this.form.fecha_solicitud}. De ser así, esta debe ser reportada en la bitácora de novedades.`,
            `Los cambios no se pueden revertir!`,
            "warning",
            "Si, Agregar Novedad",
            "No, Realizar Cierre Operativo"
          );

          if (alert.value) {
            this.cargando = true;
            await this.$store.dispatch("getMenus", 1);
            await this.$store.dispatch(
              "getRoutes",
              this.$store.getters.getMenus
            );
            await this.$store.dispatch("setRoutes");
            await this.permisoLineaNegocio(1);
            this.cargando = false;
            return this.$router.replace("/Admin/BitacoraNovedades");
          } else {
            await this.procesarCierre(1);
          }
        }
      } else {
        this.cargando = false;
        let alert = await this.mostrarAlert(
          `¿Está seguro de quitar el cierre de la operación de ${this.form.sitio.nombre}?`,
          "Los cambios no se pueden revertir!",
          "warning",
          "Si, Quitar!",
          "No, Quitar!"
        );

        if (alert.value) {
          await this.procesarCierre(2);
        } else {
          this.form.check_cierre = true;
          this.cargando = false;
        }
      }
    },

    async procesarCierre(accion) {
      this.cargando = true;
      let data = {
        id: this.form.id,
        accion: accion,
      };
      await axios
        .post("/api/hidrocarburos/cierresOperativos/saveCierre", data)
        .then(async (response) => {
          this.$route.params.data_edit = response.data.cierre;
          await this.init();
          await this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
      this.cargando = false;
    },

    async mostrarAlert(title, text, icon, confirmText, cancelText) {
      return await this.$swal({
        title,
        text,
        icon,
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn-sm btn-confirm",
          cancelButton: "btn-sm btn-cancel",
        },
      });
    },

    async permisoLineaNegocio(linea_negocio) {
      let lineasUser = this.$store.state.user.lineas_negocio;
      return (
        lineasUser.find((linea) => linea.id === linea_negocio) !== undefined
      );
    },

    async getNovedad() {
      let data = {
        fecha: this.form.fecha_solicitud,
        sitio_id: this.form.sitio_id,
        estado: 1,
      };
      await axios
        .get("/api/admin/bitacoraNovedades/lista", {
          params: data,
        })
        .then((response) => {
          this.bitacoras = response.data;
        });
    },
  },

  async beforeMount() {
    await this.getSitios();
    await this.getEstados();
    await this.getTipoSitio();
  },

  async mounted() {
    await this.init();
  },
};
</script>
<style>
.btn-sm {
  font-size: 12px;
  padding: 5px 10px;
}

.btn-confirm {
  background-color: #3085d6;
  color: white;
  border-radius: 4px;
  border: none;
}

.btn-cancel {
  background-color: #d33;
  color: white;
  border-radius: 4px;
  border: none;
}
</style>
