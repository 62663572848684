<template>
  <div>
    <div class="card-body pb-2">
      <div class="row justify-content-center">
        <div class="form-group col-md-3">
          <label>Sitio</label>
          <v-select
            v-model="selectedSitio"
            :options="sitios"
            placeholder="Todos los Sitios"
            multiple
          ></v-select>
        </div>
        <div class="form-group col-md-3">
          <label>Año (YYYY)</label>
          <select v-model="ano" class="form-control">
            <option value="">Todos los Años</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <!-- Agrega los meses restantes -->
          </select>
        </div>
        <div class="form-group col-md-3">
          <label>Mes</label>
          <select v-model="mes" class="form-control">
            <option value="">Todos los Meses</option>
            <option value="1">Enero</option>
            <option value="2">Febrero</option>
            <option value="3">Marzo</option>
            <option value="4">Abril</option>
            <option value="5">Mayo</option>
            <option value="6">Junio</option>
            <option value="7">Julio</option>
            <option value="8">Agosto</option>
            <option value="9">Septiembre</option>
            <option value="10">Octubre</option>
            <option value="11">Noviembre</option>
            <option value="12">Diciembre</option>
            <!-- Agrega los meses restantes -->
          </select>
        </div>

        <div class="form-group col-md-3" style="margin-top: 30px">
          <button class="btn btn-primary" @click="filtrarDatos">Filtrar</button>
        </div>
      </div>
    </div>

    <div class="text-center mb-4">
      <span class="badge badge-dark text-white">
        <h4>
          Eventos con afectación a operación de Transporte Frontera Energy
        </h4>
      </span>
    </div>

    <div class="card">
      <highcharts
        :options="chartOptions"
        v-if="chartOptions.series[0].data && chartOptions.series[0].data.length > 0"
      ></highcharts>
      <div class="text-center" v-else>
        <span class="text-bold badge alert-default-info m-3">
          NO EXISTEN DATOS PARA LAS FECHAS SELECCIONADAS
        </span>
      </div>
    </div>
    <div class="card">
      <highcharts
        :options="secondChartOptions"
        v-if="secondChartOptions.series.length > 0"
      ></highcharts>
      <div class="text-center" v-else>
        <span class="text-bold badge alert-default-info m-3">
          NO EXISTEN DATOS PARA LAS FECHAS SELECCIONADAS
        </span>
      </div>
    </div>
    <div class="card">
      <highcharts
        :options="thirdChartOptions"
        v-if="thirdChartOptions.series.length > 0"
      ></highcharts>
      <div class="text-center" v-else>
        <span class="text-bold badge alert-default-info m-3">
          NO EXISTEN DATOS PARA LAS FECHAS SELECCIONADAS
        </span>
      </div>
    </div>
  </div>
</template>


<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "BitacoraNovedadesGraficas",
  components: {
    highcharts: Chart,
    vSelect,
  },

  data() {
    return {
      selectedSitio: null,
      mes: "",
      ano: "",
      sitios: [],

      chartOptions: {
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "Sitios",
          },
        },

        yAxis: [
          {
            title: {
              text: "Cantidad de eventos",
            },
          },
          {
            title: {
              text: "Duración del evento en días",
            },
            opposite: true,
          },
        ],

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "Cantidad de eventos y duracion de eventos por sitio",
          align: "center",
        },

        series: [
          {
            name: "Cantidad de eventos",
            data: null,
            yAxis: 0,
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#ff1c79"],
                [1, "#001871"],
              ],
            },
          },
          {
            name: "Duración del evento en días",
            data: null,
            yAxis: 1,
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#e78155"],
                [1, "#001871"],
              ],
            },
          },
        ],
        credits: {
          enabled: false,
        },
      },
      secondChartOptions: {
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "Sitios",
          },
        },

        yAxis: [
          {
            title: {
              text: "Cantidad de eventos",
            },
          },
        ],

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "Cantidad de eventos por sitio",
          align: "center",
        },

        series: [
          {
            name: "Cantidad de eventos",
            data: null,
            yAxis: 0,
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [[0, "#001871"]],
            },
          },
        ],
        credits: {
          enabled: false,
        },
      },
      thirdChartOptions: {
        chart: {
          type: "column",
        },
        xAxis: {
          categories: [],
          title: {
            text: "Sitios / Año",
          },
        },

        yAxis: [
          {
            title: {
              text: "Cantidad de eventos",
            },
          },
        ],

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "Cantidad de eventos por sitio y año",
          align: "center",
        },

        series: [
          {
            name: "Cantidad de eventos",
            data: null,
            yAxis: 0,
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [[0, "#001871"]],
            },
          },
        ],
        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  created() {
    this.getGrafica();
  },
  watch: {
    selectedSitio(newSitio, oldSitio) {
      if (newSitio !== oldSitio) {
        this.graficaNovedadSitios(this.respuesta.graficaCantNovedades);
        this.graficaNovedad(this.respuesta.graficaSitiNovedades);
        this.graficaEventsYear(this.respuesta.eventsYear);
      }
    },
  },

  methods: {
    async getGrafica() {
      this.$parent.cargando = true;
      try {
        const response = await axios.get(
          "/api/admin/bitacoraNovedades/getGrafica",
          {
            params: {
              mes: this.mes,
              ano: this.ano,
            },
          }
        );
        this.respuesta = response.data;
        this.sitios = Object.values(this.respuesta.graficaCantNovedades).map(
          (item) => item.nSitio
        );
        this.graficaNovedadSitios(this.respuesta.graficaCantNovedades);
        this.graficaNovedad(this.respuesta.graficaSitiNovedades);
        this.graficaEventsYear(this.respuesta.eventsYear);
        this.$parent.cargando = false;
      } catch (error) {
        console.error("Error al obtener datos:", error);
        this.$parent.cargando = false;
      }
    },

    async filtrarDatos() {
      await this.getGrafica();
    },

    async graficaNovedadSitios(resp) {
      let sitios = [];
      let novedades = [];
      let cantDias = [];

      if (!this.selectedSitio || this.selectedSitio.length < 1) {
        resp.forEach((item) => {
          sitios.push(item.nSitio);
          novedades.push(item.novedades);
          if (item.dias != null) {
            cantDias.push(item.dias);
          } else {
            cantDias.push(0);
          }
        });
      } else {
        this.selectedSitio.forEach((selected) => {
          const filteredData = Object.values(resp).filter(
            (item) => item.nSitio === selected
          );

          filteredData.forEach((item) => {
            sitios.push(item.nSitio);
            novedades.push(item.novedades);
            if (item.dias != null) {
              cantDias.push(item.dias);
            } else {
              cantDias.push(0);
            }
          });
        });
      }

      this.chartOptions.xAxis.categories = sitios;
      this.chartOptions.series[0].data = novedades;
      this.chartOptions.series[1].data = cantDias;
    },

    async graficaNovedad(resp) {
      let sitios = [];
      let novedades = [];

      if (!this.selectedSitio || this.selectedSitio.length < 1) {
        Object.keys(resp).forEach((nombreSitio) => {
          sitios.push(nombreSitio);
          const novedadesSitio = resp[nombreSitio];
          novedades.push(novedadesSitio);
        });
      } else {
        this.selectedSitio.forEach((selected) => {
          const selectedSiteData = resp[selected];
          sitios.push(selected);
          novedades.push(selectedSiteData);
        });
      }

      this.secondChartOptions.xAxis.categories = sitios;
      const seriesData = {};

      for (let index = 0; index < novedades.length; index++) {
        const novedad = novedades[index];
        for (const key in novedad) {
          if (Object.prototype.hasOwnProperty.call(novedad, key)) {
            if (!seriesData[key]) {
              seriesData[key] = [];
            }
            seriesData[key].push(novedad[key]);
          }
        }
      }

      this.secondChartOptions.series = Object.keys(seriesData).map((key) => ({
        name: key,
        data: seriesData[key],
      }));
    },

    async graficaEventsYear(finalData) {
      const categories = [];
      const series = {};

      finalData.forEach((item) => {
        const yearSitio = `${item.year} - ${item.sitio}`;
        if (!categories.includes(yearSitio)) {
          categories.push(yearSitio);
        }

        item.novedades.forEach((novedad) => {
          const tipoNovedad = novedad.descripcion;
          if (!series[tipoNovedad]) {
            series[tipoNovedad] = {
              name: tipoNovedad,
              data: Array(categories.length).fill(0),
            };
          }
          const categoryIndex = categories.indexOf(yearSitio);
          series[tipoNovedad].data[categoryIndex] = novedad.cantidad;
        });
      });

      // Fill missing data points for each series
      categories.forEach((category, index) => {
        Object.values(series).forEach((serie) => {
          if (serie.data.length <= index) {
            serie.data.push(0);
          }
        });
      });

      Object.values(series).forEach((serie) => {
        for (let i = 0; i < categories.length; i++) {
          if (serie.data[i] === undefined) {
            serie.data[i] = 0;
          }
        }
      });

      const seriesData = Object.values(series);

      this.thirdChartOptions.xAxis.categories = categories;
      this.thirdChartOptions.series = seriesData;
    },
  },
};
</script>
