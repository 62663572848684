<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Rutas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Rutas</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Rutas</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Sitios"
                    v-if="id != null"
                    >Sitios y Puntos de Gestión</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Rutas"
                    v-if="id != null && $store.getters.can('admin.rutas.edit')"
                    >Ruta</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label for="nombre">Nombre Ruta</label>
                        <input
                          type="text"
                          maxlength="100"
                          class="form-control form-control-sm"
                          id="nombre"
                          v-model="form.nombre"
                          :class="
                            $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        />
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="codigo">Código</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            id="codigo"
                            v-model="form.codigo"
                            :class="
                              $v.form.codigo.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="form-group col-md-3">
                        <label for="origen">Origen</label>
                        <v-select
                          :class="[
                            $v.form.origen_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="origen"
                          placeholder="Origen"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.origenes"
                          @input="selectOrigen()"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-3">
                        <label for="destino">Destino</label>
                        <v-select
                          :class="[
                            $v.form.destino_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="destino"
                          placeholder="Destino"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.destinos"
                          @input="selectDestino()"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="horas_vigencia">Horas de Vigencia</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          id="horas_vigencia"
                          v-model="form.horas_vigencia"
                          :class="
                            $v.form.horas_vigencia.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="tipo_ruta">Tipo de ruta</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.tipo_ruta"
                            :class="
                              $v.form.tipo_ruta.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tipo_ruta in listasForms.tipoRutas"
                              :key="tipo_ruta.numeracion"
                              :value="tipo_ruta.numeracion"
                            >
                              {{ tipo_ruta.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="tipo_despachado">Tipo Despachado</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.tipo_despachado"
                            :class="
                              $v.form.tipo_despachado.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @change="limpiarDespachado()"
                          >
                            <option value="">Seleccione...</option>
                            <option value="1">ÚNICO DESPACHADO</option>
                            <option value="2">MULTIDESPACHADO</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4" v-if="form.tipo_despachado">
                        <label for="fecha_expedicion">DESPACHADO A: </label>
                        <v-select
                          :class="[
                            $v.despachados.$invalid ? 'is-invalid' : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-if="form.tipo_despachado == 2"
                          v-model="despachados"
                          placeholder="Despachados"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.despachados"
                          multiple
                          @input="selectDespachados()"
                        >
                        </v-select>

                        <select
                          class="form-control form-control-sm"
                          v-model="form.despachado_a_id"
                          :class="[
                            $v.form.despachado_a_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-else
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="despachado in listasForms.despachados"
                            :key="despachado.numeracion"
                            :value="despachado.numeracion"
                          >
                            {{ despachado.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Sitios">
                  <div class="card-body">
                    <RutaSitio v-if="id != null"></RutaSitio>
                  </div>
                </div>
                <div class="tab-pane fade" id="Rutas">
                  <div class="card-body">
                    <RutaDetalle ref="RutaDetalle"></RutaDetalle>
                  </div>
                  <!-- <div class="card-body">
                    <RutaPolilineas v-if="id != null"></RutaPolilineas>
                  </div> -->
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      $store.getters.can('admin.rutas.create') ||
                        $store.getters.can('admin.rutas.edit')
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import RutaSitio from "./RutaSitio";
import RutaDetalle from "./RutaDetalle";
// import RutaPolilineas from "./RutaPolilineas";
const google = window.google;

export default {
  name: "RutaForm",
  components: {
    Loading,
    vSelect,
    RutaSitio,
    RutaDetalle,
    // RutaPolilineas,
  },

  data() {
    return {
      cargando: false,
      form: {
        nombre: null,
        origen_id: null,
        destino_id: null,
        horas_vigencia: null,
        tipo_ruta: null,
        tipo_despachado: null,
        despachado_a_id: null,
        redireccionamiento: false,
      },
      accion: "",
      metodo: "",
      origen: [],
      destino: [],
      polilinea_ruta: [],
      origen_form: null,
      destino_form: null,
      despachados: [],
      ver_form_ruta: false,
      ruta: {},
      listasForms: {
        tipoRutas: [],
        origenes: [],
        destinos: [],
        despachados: [],
        estados: [],
      },
      id: null,
    };
  },

  validations() {
    if (this.form.tipo_despachado == 1) {
      return {
        form: {
          nombre: {
            required,
          },
          origen_id: {
            required,
          },
          destino_id: {
            required,
          },
          codigo: {
            required,
          },
          horas_vigencia: {
            required,
          },
          tipo_ruta: {
            required,
          },
          tipo_despachado: {
            required,
          },
          despachado_a_id: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          nombre: {
            required,
          },
          origen_id: {
            required,
          },
          destino_id: {
            required,
          },
          codigo: {
            required,
          },
          horas_vigencia: {
            required,
          },
          tipo_ruta: {
            required,
          },
          tipo_despachado: {
            required,
          },
        },
        despachados: {
          required,
        },
      };
    }
  },

  methods: {
    getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        axios
          .get("/api/admin/rutas/getRuta/" + this.id)
          .then((response) => {
            this.cargando = false;
            this.form = response.data;
            this.ruta = response.data;
            this.origen_form = this.form.origen_id;
            this.destino_form = this.form.destino_id;
            this.origen = {
              id: this.form.origen_id,
              nombre: this.form.origen.nombre,
              latitud: this.form.origen.latitud,
              longitud: this.form.origen.longitud,
            };
            this.destino = {
              id: this.form.destino_id,
              nombre: this.form.destino.nombre,
              latitud: this.form.destino.latitud,
              longitud: this.form.destino.longitud,
            };
            if (this.form.tipo_despachado == 2) {
              this.showDespachado();
            }
            if (this.id && this.$refs.RutaDetalle) {
              this.$refs.RutaDetalle.getIndex();
            }
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        this.metodo = "POST";
        this.cargando = false;
      }
    },

    getDespachados() {
      axios.get("/api/lista/54").then((response) => {
        this.listasForms.despachados = response.data;
      });
    },

    getListaSitio() {
      axios.get("/api/lista/18").then((response) => {
        this.listasForms.listaSitios = response.data;
      });
    },

    showDespachado() {
      axios
        .get("/api/admin/rutas/despachados/" + this.$route.params.data_edit.id)
        .then((response) => {
          this.despachados = response.data.rutas_despachados;
        });
    },

    buscarOrigenes() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.origenes = respuesta;
          me.form.origen_id = me.listasForms.origenes.id;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectOrigen() {
      this.form.origen_id = "";
      if (this.origen) {
        this.form.origen_id = this.origen.id;
        if (this.form.origen_id == this.form.destino_id) {
          this.form.destino_id = null;
          this.destino = null;
          this.$swal({
            icon: "error",
            title: `El Origen y Destino de la ruta no puede ser el mismo...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        this.calcularRuta();
      }
    },

    buscarDestinos() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.destinos = respuesta;
          me.form.destino_id = me.listasForms.destinos.id;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectDestino() {
      this.form.destino_id = "";
      if (this.destino) {
        this.form.destino_id = this.destino.id;
        if (this.form.destino_id === this.form.origen_id) {
          this.form.destino_id = null;
          this.destino = null;
          this.$swal({
            icon: "error",
            title: `El Origen y Destino de la ruta no puede ser el mismo...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        this.calcularRuta();
      }
    },

    //Multi Despachado
    selectDespachados() {
      this.form.despachados = [];
      if (this.despachados) {
        this.form.despachados = this.despachados;
      }
    },

    limpiarDespachado() {
      this.form.despachado_a_id = null;
      this.form.despachados = {};
      this.despachados = [];
    },

    async save() {
      if (!this.$v.form.$invalid) {
        let flag = true;
        if (
          this.id &&
          (this.origen_form != this.form.origen_id ||
            this.destino_form != this.form.destino_id)
        ) {
          await this.$swal({
            title: "Está seguro de editar esta ruta?",
            text:
              "Se ha modificado el origen o el destino de esta ruta y se reestablecerán las velocidades máximas que se hayan modificado y deverán ser ajustadas nuevamente!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Modificar!",
          }).then((result) => {
            if (result.value) {
              flag = true;
              this.form.flag_path = true;
            } else {
              flag = false;
            }
          });
        }

        if (flag) {
          this.form.path = this.polilinea_ruta;
          this.cargando = true;
          axios({
            method: this.metodo,
            url: "/api/admin/rutas",
            data: this.form,
          })
            .then((response) => {
              this.cargando = false;
              if (response.data.status == 0) {
                this.$swal({
                  icon: "error",
                  title: response.data.msg,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$route.params.id = response.data;
                this.getIndex();
                this.$swal({
                  icon: "success",
                  title: "La Ruta se guardó exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      }
    },

    calcularRuta() {
      if (
        this.origen.latitud &&
        this.origen.longitud &&
        this.destino.latitud &&
        this.destino.longitud
      ) {
        // Se calcula la ruta entre los dos puntos
        const directionsService = new google.maps.DirectionsService();
        const request = {
          origin: {
            lat: parseFloat(this.origen.latitud),
            lng: parseFloat(this.origen.longitud),
          },
          destination: {
            lat: parseFloat(this.destino.latitud),
            lng: parseFloat(this.destino.longitud),
          },
          waypoints: [],
          travelMode: "DRIVING",
        };

        directionsService.route(request, (result, status) => {
          if (status === "OK") {
            this.polilinea_ruta = [];
            // Usando los path de los pasos que indica google routes
            result.routes.forEach((route) => {
              route.legs.forEach((leg) => {
                leg.steps.forEach((stp) => {
                  const pth_lngth =
                    stp.path.length < 20 ? 5 : stp.path.length > 1000 ? 20 : 10;
                  for (let i = 0; i < stp.path.length; i += pth_lngth) {
                    const element = stp.path[i];
                    this.polilinea_ruta.push({
                      lat: element.lat(),
                      lng: element.lng(),
                    });
                  }
                });
              });
            });
          }
        });
      }
    },

    getTipoRutas() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipoRutas = response.data;
      });
    },

    back() {
      return this.$router.replace("/Admin/Rutas");
    },
  },

  beforeMount() {
    this.getListaSitio();
    this.getTipoRutas();
    this.getDespachados();
    this.buscarDestinos();
    this.buscarOrigenes();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
