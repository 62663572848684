<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulaciones Nacionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="float-right">
                  <button type="button" class="btn bg-navy" @click="init()">
                    <i class="fas fa-sync-alt fa-spin"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="limpiarFiltros()"
                  >
                    <i class="fas fa-broom"></i>
                  </button>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  style="font-size: 12px; overflow-x: auto"
                  class="table table-bordered table-striped table-hover table-sm team-roster-table"
                >
                  <thead>
                    <tr>
                      <th>
                        # Solicitud
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.id"
                          @keyup.enter="init()"
                        />
                      </th>
                      <th class="text-nowrap">
                        Tipo Servicio
                        <v-select
                          v-model="tipo_servicio"
                          label="descripcion"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                            'form-control form-control-sm p-0',
                          ]"
                          :options="listasForms.tipoSolicitudes"
                          @input="selectTipoServicio()"
                          :append-to-body="true"
                        ></v-select>
                      </th>
                      <th class="text-nowrap p-3">
                        Tipo Vehículo/Equipo
                      </th>
                      <th class="p-3">Cant</th>
                      <th>
                        Empresa
                        <v-select
                          v-model="empresa"
                          label="razon_social"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                            'form-control form-control-sm p-0',
                          ]"
                          :options="listasForms.empresas"
                          @input="selectEmpresa()"
                          :append-to-body="true"
                        ></v-select>
                      </th>
                      <th>
                        Fecha Inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_inicial"
                          @input="init()"
                        />
                      </th>
                      <th class="text-nowrap p-3">Tiempo Servicio</th>
                      <th class="text-nowrap p-3">
                        Fecha Asignación
                      </th>
                      <th class="text-nowrap p-3">
                        Fecha Postulación
                      </th>
                      <th class="text-nowrap">
                        Estado Solicitud
                        <v-select
                          v-model="estado_solicitud"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                            'form-control form-control-sm p-0',
                          ]"
                          label="descripcion"
                          :options="listasForms.estados"
                          @input="selectEstado()"
                          :append-to-body="true"
                        ></v-select>
                      </th>
                      <th class="p-2">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(asignacion, index) in asignaciones.data"
                      :key="index"
                    >
                      <td class="text-center">
                        {{
                          asignacion.solicitud_tipo_servicio
                            .cs_solicitud_nacional_id
                        }}
                      </td>
                      <td class="text-center">
                        {{ asignacion.solicitud_tipo_servicio.nTipoServicio }}
                      </td>
                      <td class="text-center">
                        <div
                          v-if="
                            asignacion.solicitud_tipo_servicio.tipo_servicio ==
                              1
                          "
                        >
                          {{
                            asignacion.solicitud_tipo_servicio.tipo_remolque !=
                            null
                              ? asignacion.solicitud_tipo_servicio.nTipoVh +
                                " / " +
                                asignacion.solicitud_tipo_servicio.nTipoRemolque
                              : asignacion.solicitud_tipo_servicio.nTipoVh
                          }}
                        </div>
                        <div v-else>
                          {{ asignacion.solicitud_tipo_servicio.nTipoEqui }}
                        </div>
                      </td>
                      <td class="text-center">
                        {{ asignacion.cantidad_vehiculos }}
                      </td>
                      <td class="text-center">
                        {{ asignacion.empresa.razon_social }}
                      </td>
                      <td class="text-center">
                        {{ asignacion.fecha_inicial }}
                      </td>
                      <td class="text-center">
                        {{ asignacion.cantidad_servicios }}
                      </td>
                      <td class="text-center">
                        <span class="badge bg-navy">
                          {{
                            moment(asignacion.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          }}
                        </span>
                      </td>
                      <td class="text-center">
                        <p
                          v-for="(postulacion, k) in asignacion.postulaciones"
                          :key="postulacion.id"
                          class="p-0 m-0"
                        >
                          <span class="badge bg-navy">
                            {{ k + 1 }}. {{ postulacion.equipo.placa }} -
                            {{
                              moment(postulacion.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            }}
                          </span>
                        </p>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            asignacion.solicitud_tipo_servicio.solicitud
                              .estado == 1
                              ? 'bg-info'
                              : asignacion.solicitud_tipo_servicio.solicitud
                                  .estado == 2
                              ? 'bg-success'
                              : asignacion.solicitud_tipo_servicio.solicitud
                                  .estado == 3
                              ? 'bg-danger'
                              : asignacion.solicitud_tipo_servicio.solicitud
                                  .estado == 4
                              ? 'bg-primary'
                              : ''
                          "
                        >
                          {{
                            asignacion.solicitud_tipo_servicio.solicitud.nEstado
                          }}
                        </span>
                      </td>
                      <td class="text-right">
                        <div class="btn-group">
                          <button
                            v-if="
                              $store.getters.can(
                                'cs.nacionalesPostulaciones.updateDate'
                              )
                            "
                            class="btn btn-sm bg-info"
                            data-toggle="modal"
                            data-target="#modal-update-date"
                            @click="openModal(asignacion)"
                          >
                            <i class="fas fa-calendar-day"></i>
                          </button>
                          <button
                            type="button"
                            v-if="
                              $store.getters.can(
                                'cs.nacionalesPostulaciones.edit'
                              )
                            "
                            @click="asignarPostulaciones(asignacion, 1)"
                            class="btn btn-sm bg-navy"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            v-if="
                              $store.getters.can(
                                'cs.nacionalesPostulaciones.show'
                              )
                            "
                            @click="asignarPostulaciones(asignacion, 2)"
                            class="btn btn-sm bg-primary"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="asignaciones.total">
                  <p>
                    Mostrando del <b>{{ asignaciones.from }}</b> al
                    <b>{{ asignaciones.to }}</b> de un total:
                    <b>{{ asignaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="asignaciones"
                  @pagination-change-page="init"
                  :limit="5"
                  class="float-right mb-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <PostulacionExport ref="PostulacionExport" />

        <!-- modal actualización fecha de asignacion -->
        <div
          class="modal fade"
          id="modal-update-date"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">
                  Actualización Fecha
                </h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="closeModal1"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row justify-content-center">
                  <div class="form-group col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <label for="fecha">Fecha Asignación</label>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="date"
                          :min="hoy"
                          v-model="modal.fecha"
                          :class="
                            $v.modal.fecha.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          class="form-control form-control-sm shadow-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="fecha">Justificación</label>
                    <textarea
                      class="form-control form-control-sm shadow-sm"
                      v-model="modal.justificacion"
                      :class="
                        $v.modal.justificacion.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    ></textarea>
                  </div>
                </div>
                <div
                  v-if="!$v.modal.$invalid"
                  class="modal-footer justify-content-between p-1"
                >
                  <button
                    class="btn bg-gradient-primary offset-md-3 shadow"
                    @click="updateDate()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import PostulacionExport from "./PostulacionExport";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "PostulacionIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    PostulacionExport,
  },
  data() {
    return {
      cargando: true,
      hoy: moment().format("YYYY-MM-DD"),
      moment: moment,
      asignaciones: {},
      filtros: {
        id: null,
        tipo_servicio: null,
        empresa_id: null,
        fecha_inicial: null,
        estado_solicitud: null,
      },
      empresa: {},
      estado_solicitud: {},
      tipo_servicio: {},
      listasForms: {
        tipoSolicitudes: [],
        empresas: [],
        estados: [],
      },
      modal: {
        id: null,
        fecha: null,
        justificacion: null,
      },
    };
  },

  validations: {
    modal: {
      fecha: {
        required,
      },
      justificacion: {
        required,
      },
    },
  },

  methods: {
    init(page = 1) {
      this.cargando = true;
      let me = this;
      axios
        .get("/api/cs/postulacionesNacionales?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.asignaciones = response.data;
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoServicio() {
      axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipoSolicitudes = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/135").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getEmpresas() {
      let me = this;
      this.cargando = true;
      var url = "api/admin/empresas/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: [4],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
          me.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
      this.init();
    },

    selectTipoServicio() {
      this.filtros.tipo_servicio = null;
      if (this.tipo_servicio) {
        this.filtros.tipo_servicio = this.tipo_servicio.numeracion;
      }
      this.init();
    },

    selectEstado() {
      this.filtros.estado_solicitud = null;
      if (this.estado_solicitud) {
        this.filtros.estado_solicitud = this.estado_solicitud.numeracion;
      }
      this.init();
    },

    limpiarFiltros() {
      this.filtros = {
        id: null,
        tipo_servicio: null,
        empresa_id: null,
        fecha_inicial: null,
        estado_solicitud: null,
      };
      this.empresa = {};
      this.tipo_servicio = {};
      this.estado_solicitud = {};
      this.init();
    },

    // Métodos de acciónes del módulo
    asignarPostulaciones(data, accion) {
      return this.$router.push({
        name: "/Cs/PostulacionesNacionalesForm",
        params: {
          asignacion_id: data.id,
          accion: accion,
        },
      });
    },

    openModal(asignacion) {
      this.modal = {
        id: asignacion.id,
        fecha: asignacion.fecha_inicial,
        justificacion: null,
      };
    },

    updateDate() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/cs/postulacionesNacionales/updateDate",
        data: this.modal,
      })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Se actualizó la fecha correctamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$refs.closeModal1.click();
          this.init();
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  beforeMount() {
    this.getEmpresas();
    this.getEstados();
    this.getTipoServicio();
  },

  mounted() {
    this.init();
  },
};
</script>
