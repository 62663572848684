<template>
  <div class="row">
    <div
      class="col-md-12 text-right mb-2"
      v-if="
        $store.getters.can('hidrocarburos.fluvialesTarifarios.createTarifas') ||
          $store.getters.can('hidrocarburos.fluvialesTarifarios.editTarifas')
      "
    >
      <div class="btn-group shadow-sm">
        <button
          data-target="#Modal_tarifas"
          data-toggle="modal"
          class="btn bg-gradient-primary"
          v-if="$store.getters.can('hidrocarburos.fluvialesTarifarios.createTarifas')"
          @click="getFormTarifa()"
        >
          <i class="fas fa-plus"></i>
        </button>
        <button
          data-target="#Modal_formato_masivo"
          data-toggle="modal"
          class="btn bg-gradient-success"
          @click="getFormFormato()"
          v-if="
            $store.getters.can('hidrocarburos.fluvialesTarifarios.createTarifas') ||
              $store.getters.can('hidrocarburos.fluvialesTarifarios.editTarifas')
          "
        >
          <i class="fa fa-file-excel"></i>
        </button>
      </div>
    </div>
    <div class="col-md-12 table-responsive">
      <table
        class="table table-bordered table-striped table-hover table-sm shadow"
        v-if="
          $parent.datos_tarifario && $parent.datos_tarifario.tarifas.length > 0
        "
      >
        <thead class="bg-gradient-secondary">
          <tr>
            <th>Nombre</th>
            <th class="text-nowrap">Tipo Servicio</th>
            <th class="text-nowrap">Fecha</th>
            <th class="text-nowrap">Valor Barril</th>
            <th class="text-nowrap">Gravado Iva</th>
            <th class="col-1">Estado</th>
            <th class="col-1">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tarifa in $parent.datos_tarifario.tarifas"
            :key="tarifa.id"
          >
            <td>{{ tarifa.nombre }}</td>
            <td>{{ tarifa.nTipoServicio }}</td>
            <td class="text-center text-nowrap">
              <div v-if="tarifa.detalles.length > 0">
                {{
                  tarifa.detalles[0].fecha_fin
                    ? `${tarifa.detalles[0].fecha_ini} - ${tarifa.detalles[0].fecha_fin}`
                    : `${tarifa.detalles[0].fecha_ini}`
                }}
              </div>
            </td>
            <td class="text-right">
              {{
                tarifa.detalles.length > 0
                  ? formatCurrency(
                      "es-CO",
                      "COP",
                      2,
                      tarifa.detalles[0].valor_barril
                    )
                  : ""
              }}
            </td>
            <td class="text-right">
              {{
                tarifa.detalles.length > 0
                  ? formatCurrency(
                      "es-CO",
                      "COP",
                      2,
                      tarifa.detalles[0].gravado_iva
                    )
                  : ""
              }}
            </td>
            <td class="text-center">
              <span
                class="badge"
                v-bind:class="[
                  tarifa.estado == 1 ? 'badge-success' : 'badge-danger',
                ]"
              >
                {{ tarifa.nEstado }}
              </span>
            </td>
            <td class="text-right">
              <div class="btn-group">
                <button
                  data-target="#Modal_tarifas"
                  data-toggle="modal"
                  class="btn btn-sm bg-gradient-navy"
                  v-if="
                    $store.getters.can('hidrocarburos.fluvialesTarifarios.editTarifas') &&
                      $parent.estado_tarifario == 1
                  "
                  @click="getFormTarifa(tarifa)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  data-target="#Modal_tarifas_detalles"
                  data-toggle="modal"
                  class="btn btn-sm bg-gradient-info"
                  v-if="
                    $store.getters.can('hidrocarburos.fluvialesTarifarios.editTarifas')
                  "
                  @click="getDataDet(tarifa)"
                >
                  <i class="fas fa-list"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="callout callout-warning text-muted m-0">
        <h5 class="m-0">No hay tarifas registradas!</h5>
      </div>
    </div>
    <!-- Modal Creación-->
    <div
      class="modal fade"
      id="Modal_tarifas"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left  p-2">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white mr-1"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalTarifa"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <div class="row justify-content-center">
              <div class="form-group col-md-12">
                <label>Nombre</label>
                <input
                  v-model="form_tarifa.nombre"
                  type="text"
                  class="form-control form-control-sm shadow-sm"
                  :class="
                    $v.form_tarifa.nombre.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="
                    (!$store.getters.can(
                      'hidrocarburos.fluvialesTarifarios.createTarifas'
                    ) &&
                      !form_tarifa.id) ||
                      (!$store.getters.can(
                        'hidrocarburos.fluvialesTarifarios.editTarifas'
                      ) &&
                        form_tarifa.id)
                  "
                />
              </div>
              <div class="form-group col-md-8">
                <label>Tipo Servicio</label>
                <select
                  v-model="form_tarifa.tipo_servicio"
                  class="form-control form-control-sm shadow-sm"
                  :class="
                    $v.form_tarifa.tipo_servicio.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_servicio in listasForms.tipos_servicios"
                    :key="tipo_servicio.numeracion"
                    :value="tipo_servicio.numeracion"
                  >
                    {{ tipo_servicio.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label>Estado</label>
                <select
                  v-model="form_tarifa.estado"
                  class="form-control form-control-sm shadow-sm"
                  :class="
                    $v.form_tarifa.estado.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="
                    !form_tarifa.id ||
                      (!$store.getters.can(
                        'hidrocarburos.fluvialesTarifarios.createTarifas'
                      ) &&
                        !form_tarifa.id) ||
                      (!$store.getters.can(
                        'hidrocarburos.fluvialesTarifarios.editTarifas'
                      ) &&
                        form_tarifa.id)
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-12">
                <label>Observaciones</label>
                <textarea
                  v-model="form_tarifa.observaciones"
                  class="form-control form-control-sm shadow-sm"
                ></textarea>
              </div>
            </div>
          </div>
          <div
            class="card-footer justify-content-between p-1"
            v-if="
              !$v.form_tarifa.$invalid &&
                ($store.getters.can('hidrocarburos.fluvialesTarifarios.createTarifas') ||
                  $store.getters.can('hidrocarburos.fluvialesTarifarios.editTarifas'))
            "
          >
            <button
              type="button"
              class="btn bg-gradient-primary shadow"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Detalle -->
    <div
      class="modal fade"
      id="Modal_tarifas_detalles"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal1"
              ref="closeModalTarifaDetalle"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--Detalle Tarifa Producción-->
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Detalle Tarifa
              </legend>
              <div class="row">
                <div class="col-md-12 text-right mb-2">
                  <button
                    class="btn bg-gradient-primary shadow-sm"
                    @click="getFormStatusDet(1)"
                    :disabled="flag_form_detalle != 0"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <div class="col-md-12">
                  <table
                    class="table table-bordered table-striped table-hover table-sm shadow"
                    v-if="det_tarifa.length > 0 || flag_form_detalle != 0"
                  >
                    <thead class="bg-gradient-secondary">
                      <tr>
                        <th class="col-3">Fecha Inicial</th>
                        <th class="col-3">Fecha Final</th>
                        <th class="col-4">Valor Barril</th>
                        <th class="col-4">Gravado Iva</th>
                        <th class="col-1">Estado</th>
                        <th class="col-1">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="flag_form_detalle == 1">
                        <td class="text-center">
                          <input
                            type="date"
                            class="form-control form-control-sm shadow-sm"
                            v-model="form_detalle.fecha_ini"
                            :class="
                              $v.form_detalle.fecha_ini.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validaFechas(null)"
                          />
                        </td>
                        <td class="text-center">
                        </td>
                        <td class="text-right">
                          <input
                            type="number"
                            class="form-control form-control-sm shadow-sm"
                            v-model="form_detalle.valor_barril"
                            :class="
                              $v.form_detalle.valor_barril.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </td>
                        <td class="text-right">
                          <input
                            type="number"
                            class="form-control form-control-sm shadow-sm"
                            v-model="form_detalle.gravado_iva"
                            :class="
                              $v.form_detalle.gravado_iva.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </td>
                        <td class="text-center">
                          <span class="badge bg-gradient-success shadow"
                            >ACTIVO</span
                          >
                        </td>
                        <td class="text-right">
                          <div class="btn-group shadow">
                            <button
                              type="button"
                              class="btn btn-sm bg-gradient-success"
                              v-if="
                                !$v.form_detalle.$invalid &&
                                  $store.getters.can(
                                    'hidrocarburos.fluvialesTarifarios.editTarifas'
                                  )
                              "
                              @click="saveDetalle"
                            >
                              <i class="fas fa-save"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm bg-gradient-danger"
                              @click="getFormStatusDet(0)"
                            >
                              <i class="fas fa-ban"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr v-for="(det, index) in det_tarifa" :key="index">
                        <td class="text-center">
                          <input
                            type="date"
                            v-if="index === 0 && flag_form_detalle == 2"
                            class="form-control form-control-sm"
                            v-model="form_detalle.fecha_ini"
                            :class="
                              $v.form_detalle.fecha_ini.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validaFechas(index)"
                          />
                          {{
                            index !== 0 || flag_form_detalle != 2
                              ? det.fecha_ini
                              : ""
                          }}
                        </td>
                        <td class="text-center">
                          {{
                            index !== 0 || flag_form_detalle != 2
                              ? det.fecha_fin
                              : ""
                          }}
                        </td>
                        <td class="text-right">
                          <input
                            type="number"
                            v-if="index === 0 && flag_form_detalle == 2"
                            class="form-control form-control-sm"
                            v-model="form_detalle.valor_barril"
                            :class="
                              $v.form_detalle.valor_barril.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                          {{ det.valor_barril }}
                        </td>
                        <td class="text-right">
                          <input
                            type="number"
                            v-if="index === 0 && flag_form_detalle == 2"
                            class="form-control form-control-sm"
                            v-model="form_detalle.gravado_iva"
                            :class="
                              $v.form_detalle.gravado_iva.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                          {{ det.gravado_iva }}
                        </td>
                        <td class="text-center">
                          <span
                            class="shadow badge"
                            v-bind:class="[
                              det.estado == 1
                                ? 'bg-gradient-success'
                                : 'bg-gradient-danger',
                            ]"
                          >
                            {{ det.nEstado }}
                          </span>
                        </td>
                        <td class="text-right">
                          <div
                            class="btn-group shadow"
                            v-if="index === 0 && flag_form_detalle == 0"
                          >
                            <button
                              type="button"
                              class="btn btn-sm bg-gradient-navy"
                              @click="getFormStatusDet(2, index)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                          <div
                            class="btn-group"
                            v-if="index === 0 && flag_form_detalle == 2"
                          >
                            <button
                              type="button"
                              class="btn btn-sm bg-gradient-success"
                              v-if="
                                !$v.form_detalle.$invalid &&
                                  $store.getters.can(
                                    'hidrocarburos.fluvialesTarifarios.editTarifas'
                                  )
                              "
                              @click="saveDetalle"
                            >
                              <i class="fas fa-save"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm bg-gradient-danger"
                              @click="getFormStatusDet(0)"
                            >
                              <i class="fas fa-ban"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="callout callout-warning text-muted m-0">
                    <h5 class="m-0">No hay detalles de tarifa registrados!</h5>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Excel Masivo-->
    <div class="modal fade" id="Modal_formato_masivo">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal_title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalTarifaFormato"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body pb-0">
            <div class="row">
              <div class="form-group col-md-12">
                <button
                  id="formatoTarifaTransporte"
                  type="button"
                  class="btn bg-gradient-olive text-nowrap shadow"
                  data-html="true"
                  title="Formato de Traifa de Transporte"
                  @click="descargarFormatoTarifaFluvial()"
                >
                  <i class="fa fa-file-excel mr-1"></i>
                  <small>Descargar Formato</small>
                </button>
              </div>
              <div
                class="form-group"
                :class="form_file.file ? 'col-md-10' : 'col-md-12'"
              >
                <label class="m-0">Carga masiva de Tarifas</label>
                <input
                  type="file"
                  id="link_formato"
                  ref="file"
                  class="form-control btn btn-light col-12 shadow-sm"
                  v-on:change="elegirDirectorio()"
                  accept=".XLSX, .CSV"
                  :class="
                    $v.form_file.file.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-2" v-if="form_file.file">
                <button
                  type="button"
                  class="btn btn-sm bg-gradient-danger shadow m-4"
                  @click="deletDoc()"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
              <div
                class="alert alert-default-warning border border-warning alert-dismissible shadow-sm pr-5"
              >
                <h5>
                  <i class="icon fas fa-exclamation-triangle"></i> Atención!
                </h5>
                <div class="row">
                  <div class="col-md-1">
                    <strong>*</strong>
                  </div>
                  <div class="col-md-11 pl-0">
                    Cambiar la columna <strong>Id</strong> del formato afectará
                    el comportamiento de las liquidaciónes, favor abstenerse de
                    cambiar esta columna.
                  </div>
                  <div class="col-md-1">
                    <strong>*</strong>
                  </div>
                  <div class="col-md-11 pl-0">
                    El campo <strong>Fecha Inicial</strong> debe tener el
                    Formato de celda Fecha tipo como el siguiente ejemplo
                    <strong>2012/03/14</strong> o <strong>2012-03-14</strong>.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-footer justify-content-between"
            v-if="
              !$v.form_file.file.$invalid &&
                ($store.getters.can('hidrocarburos.fluvialesTarifarios.createTarifas') ||
                  $store.getters.can('hidrocarburos.fluvialesTarifarios.editTarifas'))
            "
          >
            <button class="btn bg-gradient-navy shadow" @click="cargarTarifas">
              <i class="fas fa-upload"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TarifariosTarifasFluviales",

  data() {
    return {
      tarifario_transporte_fluvial_id: null,
      tarifas_transporte_fluvial_id: null,
      tarifa_estado: null,
      modal_title: "",
      listasForms: {
        equipos_types: [],
        tipo_tiempos_tarifas: [],
        clases_tarifas: [],
        unidades_medida: [],
        estados: [],
        tipos_vehiculos: [],
        tipos_servicios: [],
      },
      form_file: {
        file: null,
      },
      form_tarifa: {},
      flag_form_detalle: 0,
      det_tarifa: {},
      form_detalle: {},
    };
  },

  validations: {
    form_tarifa: {
      nombre: {
        required,
      },
      tipo_servicio: {
        required,
      },
      estado: {
        required,
      },
    },
    form_detalle: {
      fecha_ini: {
        required,
      },
      valor_barril: {
        required,
      },
      gravado_iva: {
        required,
      },
      estado: {
        required,
      },
    },

    form_file: {
      file: {
        required,
      },
    },
  },

  methods: {
    getIndex() {
      this.tarifario_transporte_fluvial_id = this.$parent.datos_tarifario.id;
    },

    // Funciones para llenado de las listas
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTiposServicios() {
      axios.get("/api/lista/217").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },


    // Funciones para los formularios
    getFormTarifa(tarifa = null) {
      this.modal_title = `Crear Tarifa`;
      this.resetFormTarifa();
      if (tarifa) {
        this.modal_title = `Editar Tarifa - ${tarifa.nombre}`;
        this.form_tarifa = {
          id: tarifa.id,
          tarifario_transporte_fluvial_id:
            tarifa.tarifario_transporte_fluvial_id,
          nombre: tarifa.nombre,
          tipo_servicio: tarifa.tipo_servicio,
          observaciones: tarifa.observaciones,
          estado: tarifa.estado,
        };
      }
    },

    resetFormTarifa() {
      this.form_tarifa = {
        id: null,
        tarifario_transporte_fluvial_id: this.tarifario_transporte_fluvial_id,
        nombre: null,
        tipo_servicio: null,
        observaciones: null,
        estado: 1,
      };
    },

    getDataDet(tarifa) {
      this.modal_title = `Histórico de Tarifa - ${tarifa.nombre}`;
      this.tarifas_transporte_fluvial_id = tarifa.id;
      this.tarifa_estado = tarifa.estado;
      this.flag_form_detalle = 0;
      this.det_tarifa = tarifa.detalles;
      //this.lenarModalAdicionales(tarifa);
    },

    getFormStatusDet(status, index = null) {
      this.flag_form_detalle = status;
      switch (status) {
        case 1:
          this.form_detalle = {
            id: null,
            tarifas_transporte_fluvial_id: this.tarifas_transporte_fluvial_id,
            valor_barril: null,
            gravado_iva: null,
            fecha_ini: null,
            fecha_fin: null,
            estado: 1,
          };
          break;
        case 2:
          this.form_detalle = {
            id: this.det_tarifa[index].id,
            tarifas_transporte_fluvial_id: this.det_tarifa[index]
              .tarifas_transporte_fluvial_id,
            valor_barril: this.det_tarifa[index].valor_barril,
            gravado_iva: this.det_tarifa[index].gravado_iva,
            fecha_ini: this.det_tarifa[index].fecha_ini,
            fecha_fin: this.det_tarifa[index].fecha_fin,
            estado: this.det_tarifa[index].estado,
          };
          break;
        default:
          break;
      }
    },

    // Funciones para validaciones de los datos
    validaFechas(index) {
      let msg_error = "";
      if (this.form_detalle.fecha_ini) {
        const fecha_menor = new Date(this.form_detalle.fecha_ini);
        if (this.det_tarifa.length > 0) {
          let pos = index == null ? 0 : 1;
          if (this.det_tarifa[pos]) {
            let fecha_limite = null;
            if (this.det_tarifa[pos].fecha_fin) {
              fecha_limite = new Date(this.det_tarifa[pos].fecha_fin);
            } else {
              fecha_limite = new Date(this.det_tarifa[pos].fecha_ini);
            }
            if (fecha_menor <= fecha_limite) {
              this.form_detalle.fecha_ini = null;
              msg_error = `La fecha inicio no puede ser menor a la fecha final de la tarifa actual...`;
            }
          }
        }
        if (this.form_detalle.fecha_ini && this.form_detalle.fecha_fin) {
          const fecha_mayor = new Date(this.form_detalle.fecha_fin);
          // Se valida que la fecha inicial sea menor que la fecha final
          if (fecha_menor > fecha_mayor) {
            this.form_detalle.fecha_fin = null;
            msg_error = `La fecha inicio no puede ser mayor a la fecha final...`;
          }
        }
      }

      if (msg_error) {
        this.$swal({
          icon: "error",
          title: msg_error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    // Funciones para las acciones de los formularios
    save() {
      if (!this.$v.form_tarifa.$invalid) {
        this.$parent.cargando = true;
        axios({
          method: "POST",
          url: "/api/hidrocarburos/TarifasFluviales",
          data: this.form_tarifa,
        })
          .then((response) => {
            this.$parent.cargando = false;
            if (!response.data.msg_error) {
              this.$refs.closeModalTarifa.click();
              this.$parent.getIndex();
              this.$swal({
                icon: "success",
                title: "El tarifario se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.form_tarifa.nombre = null;
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveDetalle() {
      if (!this.$v.form_detalle.$invalid) {
        this.$parent.cargando = true;
        axios({
          method: "POST",
          url: "/api/hidrocarburos/TarifasFluviales/detalle",
          data: this.form_detalle,
        })
          .then(() => {
            this.$parent.cargando = false;
            this.$refs.closeModalTarifaDetalle.click();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "El detalle de la tarifa se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    // Funciones Adjuntar Data Masiva
    getFormFormato() {
      this.modal_title = `Formato de Tarifas`;
      this.form_file.file = null;
      document.getElementById("link_formato").value = "";
    },

    elegirDirectorio() {
      this.form_file.file = this.$refs.file.files[0];
    },

    descargarFormatoTarifaFluvial() {
      const me = this;
      me.$parent.cargando = true;
      axios
        .get(`api/hidrocarburos/TarifariosFluviales/excelDetalle`, {
          params: {
            tarifario_transporte_fluvial_id: me.tarifario_transporte_fluvial_id,
          },
        })
        .then(function(response) {
          let data = response.data;
          me.$parent.cargando = false;
          location.href = data.url;
          me.$swal({
            icon: "success",
            title: data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    cargarTarifas() {
      this.$parent.cargando = true;
      let me = this;
      let formData = new FormData();
      formData.append("file_formato", this.form_file.file);
      formData.append(
        "tarifario_transporte_fluvial_id",
        this.tarifario_transporte_fluvial_id
      );
      axios
        .post("/api/hidrocarburos/TarifasFluviales/importTarifas", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let result_content = response.data.counter
            ? `<div>
                <p class="text-success">
                  <strong>Se actualizaron ${response.data.counter} registros.</strong>
                </p>
              </div>`
            : "";

          if (response.data.error && response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados.</h4>`;
            response.data.error.forEach(
              (err) => (result_content += `<div><small>${err}</small></div>`)
            );
          }

          this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: "success",
            showConfirmButton: false,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          });

          me.$parent.cargando = false;
          this.$refs.closeModalTarifaFormato.click();
          this.$parent.getIndex();
        })
        .catch(function(error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    deletDoc() {
      this.form_file.file = null;
      document.getElementById("link_formato").value = "";
    },

  },

  beforeMount() {
    this.getEstados();
    this.getTiposServicios();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
</style>
