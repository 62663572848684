<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Remolcadores</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Remolcadores</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right shadow">
                      <!-- <button
                        type="button"
                        class="btn bg-gradient-info"
                        @click="pivotTable()"
                        v-if="$store.getters.can('admin.vehiculos.pivotTable')"
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-gradient-success"
                        data-toggle="modal"
                        data-target="#modal_form_vehiculo_export"
                        v-if="$store.getters.can('admin.vehiculos.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button> -->
                      <button
                        type="button"
                        class="btn bg-gradient-primary"
                        v-if="$store.getters.can('admin.remolcadores.create')"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-gradient-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0 table-responsive">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <!-- <th>
                        Matricula
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.matricula"
                          @keyup="getIndex()"
                        />
                      </th> -->
                      <th>
                        Líneas Negocio
                      </th>
                      <th style="width: 150px;">
                        Bandera
                        <v-select
                          v-model="bandera"
                          placeholder="bandera"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.paises"
                          @input="selectBandera()"
                        ></v-select>
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.selct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Fecha Registro
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_registro"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center" style="width: 130px;">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="remolcador in remolcadores.data"
                      :key="remolcador.id"
                    >
                      <td>
                        <a
                          :href="uri_docs + remolcador.link_imagen"
                          target="_blank"
                        >
                          <img
                            :src="uri_docs + remolcador.link_imagen"
                            alt="Remolcador"
                            width="75"
                            class="rounded mx-auto d-block shadow"
                          />
                        </a>
                      </td>
                      <td>{{ remolcador.nombre }}</td>
                      <!-- <td>{{ remolcador.matricula }}</td> -->
                      <td class="text-center">
                        <span class="badge bg-navy">
                          {{
                            remolcador.linea_negocio
                              ? remolcador.linea_negocio.nombre
                              : ""
                          }}
                        </span>
                      </td>
                      <td>
                        {{
                          remolcador.pais != null ? remolcador.pais.nombre : ""
                        }}
                      </td>
                      <td>
                        {{
                          remolcador.empresa != null
                            ? remolcador.empresa.razon_social
                            : "No encontrada"
                        }}
                      </td>
                      <td>{{ remolcador.fecha_registro }}</td>
                      <td style="width: 85px" align="right">
                        <div class="row">
                          <div class="col-md-6">
                            <span
                              class="badge shadow-sm"
                              data-toggle="modal"
                              data-target="#modal-form-estado"
                              style="cursor: pointer"
                              v-bind:class="[
                                remolcador.estado == 1
                                  ? 'badge-success'
                                  : 'badge-danger',
                              ]"
                              @click="
                                llenarModal(remolcador.id, remolcador.estado)
                              "
                            >
                              {{
                                remolcador.estado == 1 ? "ACTIVO" : "INACTIVO"
                              }}
                            </span>
                          </div>
                          <div class="col-md-6">
                            <i
                              class="fas fa-eye"
                              data-toggle="modal"
                              data-target="#modal-historial-estado"
                              style="cursor: pointer"
                              v-if="$store.getters.can('admin.remolcadores.show')"
                              @click="
                                llenarModalHistorial(
                                  'App\\Remolcador',
                                  remolcador.id
                                )
                              "
                            ></i>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="btn-group float-right shadow">
                          <!-- <div
                            v-if="
                              $store.getters.can(
                                'admin.vehiculos.editarTransportadora'
                              ) && !admin
                            "
                          >
                            <button
                              type="button"
                              class="btn btn-sm bg-gradient-navy"
                              @click="edit(remolcador, 3)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div> -->

                          <button
                            type="button"
                            class="btn btn-sm bg-gradient-navy"
                            v-if="$store.getters.can('admin.remolcadores.show')"
                            @click="edit(remolcador, 1)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>

                          <button
                            type="button"
                            class="btn btn-sm bg-gradient-navy"
                            v-if="$store.getters.can('admin.remolcadores.edit')"
                            @click="edit(remolcador, 2)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <!-- <button
                            type="button"
                            class="btn btn-sm bg-gradient-danger"
                            v-if="$store.getters.can('admin.remolcadores.delete')"
                            @click="destroy(remolcador.id)"
                          >
                            <i class="fas fa-trash"></i>
                          </button> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="remolcadores.total">
                  <p>
                    Mostrando del <b>{{ remolcadores.from }}</b> al
                    <b>{{ remolcadores.to }}</b> de un total:
                    <b>{{ remolcadores.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="remolcadores"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <RemolcadorEstado ref="RemolcadorEstado" />
        <HistorialEstado ref="HistorialEstado" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import RemolcadorEstado from "./RemolcadorEstado";
import HistorialEstado from "../historialEstado/HistorialEstado";

export default {
  name: "RemolcadorIndex",
  components: {
    pagination,
    vSelect,
    Loading,
    RemolcadorEstado,
    HistorialEstado,
  },
  data() {
    return {
      linea_negocio: [],
      empresas: [],
      bandera: [],
      empresa: this.$store.getters.getUser.empresas,
      cargando: false,
      filtros: {
        nombre: null,
        matricula: null,
        bandera: null,
        empresa_id: null,
        estado: null,
      },
      vehiculos: {},
      remolcadores: {},
      listasForms: {
        empresas: [],
        estados: [],
        lineas_negocio: [],
        selct_empresa: [],
        paises: [],
      },
      
      //uri_docs: "http://localhost:8000/",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {},
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.filtros.empresa_id = null;
      this.filtros.marca_id = null;
      this.filtros.combustible_id = null;
      if (this.listasForms.selct_empresa) {
        this.filtros.empresa_id = this.listasForms.selct_empresa.id;
      }
      axios
        .get("/api/admin/vehiculos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.vehiculos = response.data;
          this.cargando = false;
        });

      axios
        .get("/api/admin/remolcadores?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.remolcadores = response.data;
          this.cargando = false;
        });

      if (this.$store.state.user) {
        const administrador = this.$store.state.user.roles.find(
          (administrador) => administrador.id === 1
        );
        if (administrador) {
          this.admin = administrador.id;
        }
      }
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getEmpresas() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getPaises() {
      axios.get("/api/admin/paises/lista").then((response) => {
        this.listasForms.paises = response.data;
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(idRemolcador) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/remolcadores/" + idRemolcador)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    selectBandera() {
      this.filtros.bandera = this.bandera ? this.bandera.id : null;
      this.getIndex();
    },

    create() {
      return this.$router.push({
        name: "/Admin/RemolcadoresForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(vehiculo, opcion) {
      return this.$router.push({
        name: "/Admin/RemolcadoresForm",
        params: {
          accion: "Editar",
          data_edit: vehiculo,
          id: vehiculo.id,
          opcion: opcion,
        },
      });
    },

    pivotTable() {
      return this.$router.push({
        name: "/Admin/RemolcadorPivotTable",
      });
    },
    llenarModal(id, estado) {
      if (this.$store.getters.can("admin.remolcadores.editStatus")) {
        this.$refs.RemolcadorEstado.llenar_modal(id, estado);
      }
    },

    llenarModalHistorial(entidad, entidad_id) {
      if (this.$store.getters.can("admin.remolcadores.show")) {
        this.$refs.HistorialEstado.llenar_modal(entidad, entidad_id);
      }
    },
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/remolcadores/export",
        data: this.form,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  mounted() {
    this.getIndex();
    this.getEmpresas();
    this.getEstados();
    this.getPaises();
    this.getLineasNegocio();
  },
};
</script>
