<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Usuarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Seguridad</li>
                  <li class="breadcrumb-item active">Usuarios</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Usuarios</h5>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="type_document">Tipo Documento</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.type_document"
                        :class="
                          $v.form.type_document.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tipo in listasForms.tipo_identificacion"
                          :key="tipo.numeracion"
                          :value="tipo.numeracion"
                        >
                          {{ tipo.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="n_document">Numero Identificación</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="n_document"
                        v-model="form.n_document"
                        :class="
                          $v.form.n_document.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="name">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="name"
                        v-model="form.name"
                        :class="
                          $v.form.name.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @input="validarLetra(form.name, 1)"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        class="form-control form-control-sm"
                        id="email"
                        v-model="form.email"
                        :class="
                          $v.form.email.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @change="emailValidacion()"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3" v-if="form.id != null">
                    <label for="bloques">Bloque</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="bloque"
                      placeholder="Bloques"
                      label="nombre"
                      :options="listasForms.bloques"
                      class="form-control form-control-sm p-0"
                      @input="getSelectBloques()"
                      multiple
                    ></v-select>
                  </div>

                  <div class="col-md-3" v-if="form.id != null">
                    <label for="clientes">Cliente</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="cliente"
                      placeholder="Cliente"
                      label="razon_social"
                      :options="listasForms.clientes"
                      class="form-control form-control-sm p-0"
                      @input="getSelectcliente()"
                    ></v-select>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="status">Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.status"
                        :class="
                          $v.form.status.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="cel">Celular</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="cel"
                        v-model="form.cel"
                        :class="
                          $v.form.cel.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-2 pt-4">
                    <div class="form-group">
                      <div
                        class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="generar_clave"
                          v-model="form.generar_clave"
                        />
                        <label class="custom-control-label" for="generar_clave"
                          >Generar Clave</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      $store.getters.can('admin.usuarios.create') ||
                      $store.getters.can('admin.usuarios.edit')
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <UsuarioPerfil v-if="form.id != null" />
              </div>
              <div class="col-md-6">
                <UsuarioLinea v-if="form.id != null" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <UsuarioEmpresa v-if="form.id != null" />
              </div>
              <div class="col-md-6">
                <UsuarioFirma v-if="form.id != null" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

import UsuarioPerfil from "./UsuarioPerfil";
import UsuarioLinea from "./UsuarioLinea";
import UsuarioEmpresa from "./UsuarioEmpresa";
import UsuarioFirma from "./UsuarioFirma";
import Loading from "../../../../components/Loading";

export default {
  name: "UsuarioForm",
  components: {
    vSelect,
    UsuarioPerfil,
    UsuarioLinea,
    UsuarioEmpresa,
    UsuarioFirma,
    Loading,
  },
  data() {
    return {
      form: {},
      error: "",
      accion: "",
      metodo: "",
      bloque: [],
      cliente: {},
      listasForms: {
        estados: [],
        clientes: [],
        tipo_identificacion: [],
        bloques: [],
      },
      id: this.$route.params.id,
      cargando: false,
    };
  },
  validations: {
    form: {
      n_document: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(13),
      },
      name: {
        required,
        minLength: minLength(8),
      },
      email: {
        required,
        email,
      },
      cel: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      status: {
        required,
      },
      type_document: {
        required,
      },
    },
  },

  methods: {
    validarLetra(palabra, opcion) {
      if (palabra.length > 0) {
        var ascii = palabra.toUpperCase().charCodeAt(palabra.length - 1);
        if (
          !(
            (ascii > 64 && ascii < 91) ||
            ascii == 32 ||
            ascii == 193 ||
            ascii == 201 ||
            ascii == 205 ||
            ascii == 211 ||
            ascii == 218
          )
        ) {
          if (opcion == 1) {
            this.form.name = palabra.slice(0, -1);
          }
        }
      }
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTiposIdentificacion() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_identificacion = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    getSelectBloques() {
      this.form.bloque = {};
      this.form.bloque_id = null;
      if (this.bloque) {
        this.form.bloque = this.bloque;
        this.form.bloque_id = this.bloque.id;
      }
    },

    getClientes() {
      axios.get("/api/com/clientes/lista").then((response) => {
        this.listasForms.clientes = response.data;
      });
    },

    getSelectcliente() {
      if (this.cliente != null) {
        this.form.cliente_id = this.cliente.id;
      } else {
        this.form.cliente_id = null;
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.bloque = this.bloque;
        this.form.accion = this.accion;
        this.form.user_update_id = this.$store.getters.getUser.id;
        axios({
          method: this.metodo,
          url: "/api/admin/usuarios",
          data: this.form,
        })
          .then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title:
                "El usuario se creo exitosamente, se enviará un correo electrónico al usuario con la contraseña...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            return this.$router.push({
              name: "/Admin/Usuarios",
              /*  params: {
                msg:
                  "El usuario se creo exitosamente, se enviara un correo electronico al usuario con la contraseña...",
              }, */
            });
          })
          .catch((e) => {
            this.cargando = false;
            if (e.response.status == 500) {
              this.error = e.response.data.message;
              if (this.error.includes("for key 'users.n_document")) {
                this.$swal({
                  icon: "error",
                  title:
                    "El numero de documento ya se encuentra en la base de datos, por favor cambielo",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                if (this.error.includes("for key 'users.email")) {
                  this.$swal({
                    icon: "error",
                    title:
                      "El email ya se encuentra en la base de datos, por favor cambielo",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                } else {
                  this.$swal({
                    icon: "error",
                    title: "Ha ocurrido un error, intentelo nuevamente",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                }
              }
            } else {
              this.$swal({
                icon: "error",
                title:
                  "Ha ocurrido un error, por favor intente la accion nuevamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          });
      }
    },

    emailValidacion() {
      this.cargando = true;
      let email = this.form.email.split("@");
      axios({
        method: "GET",
        url: "/api/admin/usuarios/emailValidacion",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        if (email[1] == "fronteraenergy.ca") {
          if (this.validar == "si") {
            this.form.funcionario = this.validar;
            this.$swal({
              icon: "success",
              title: `Ya existe un funcionario con este correo.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.form.funcionario = this.validar;
            this.$swal({
              icon: "warning",
              title: `No existe un funcionario con este correo.`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }
      });
    },

    back() {
      return this.$router.replace("/Admin/Usuarios");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.showBloque();
        this.getBloques();

        if (this.form.cliente_id !== null) {
          this.cliente = this.form.cliente;
        }
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
      this.getClientes();
    },

    showBloque() {
      axios
        .get("/api/admin/usuarios/bloques/" + this.$route.params.data_edit.id)
        .then((response) => {
          this.bloque = response.data;
        });
    },
  },
  mounted() {
    this.getEstados();
    this.getTiposIdentificacion();
    this.init();
  },
};
</script>
