<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tarifarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Tarifarios Fluviales</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right shadow">
                      <button
                        type="button"
                        class="btn bg-gradient-primary"
                        @click="create()"
                        v-if="
                          $store.getters.can('hidrocarburos.fluvialesTarifarios.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th class="text-center">
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm shadow-sm"
                          v-model="filtros.nombre"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Tipo tarifario
                        <select
                          class="form-control form-control-sm shadow-sm"
                          v-model="filtros.tipo_tarifario"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="tipo_tarifario in listasForms.tipo_tarifarios"
                            :key="tipo_tarifario.numeracion"
                            :value="tipo_tarifario.numeracion"
                          >
                            {{ tipo_tarifario.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th style="width: 150px" class="text-center">
                        Estado
                        <select
                          class="form-control form-control-sm shadow-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="tarifario in tarifarios.data"
                      :key="tarifario.id"
                    >
                      <td>{{ tarifario.nombre }}</td>
                      <td class="text-center"><span class="shadow-sm badge bg-gradient-light" > {{ tarifario.nTipoTarifa }}</span></td>
                      <td class="text-center">
                        <span
                          class="shadow-sm badge"
                          v-bind:class="[
                            tarifario.estado == 1
                              ? 'bg-gradient-warning'
                              : 'bg-gradient-success',
                          ]"
                        >
                          {{
                            tarifario.estado == 1 ? "Borrador" : "Definitivo"
                          }}
                        </span>
                      </td>
                      <td class="text-right" style="width: 50px">
                        <div class="btn-group shadow-sm float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-gradient-navy"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.fluvialesTarifarios.show'
                              )
                            "
                            @click="edit(tarifario)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-gradient-danger"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.fluvialesTarifarios.delete'
                              )
                            "
                            :disabled="tarifario.estado != 1"
                            @click="destroy(tarifario.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="tarifarios.total">
                  <p>
                    Mostrando del <b>{{ tarifarios.from }}</b> al
                    <b>{{ tarifarios.to }}</b> de un total:
                    <b>{{ tarifarios.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="tarifarios"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";

export default {
  name: "TarifariosIndex",
  components: {
    pagination,
    Loading,
  },
  data() {
    return {
      cargando: false,
      tarifarios: {},
      listasForms: {
        estados: [],
        tipo_tarifarios: [],
      },
      filtros: {
        nombre: null,
        estado: null,
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/TarifariosFluviales?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.tarifarios = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTiposTarifarios() {
      axios.get("/api/lista/216").then((response) => {
        this.listasForms.tipo_tarifarios = response.data;
      });
    },

    destroy(idTarifario) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/TarifariosFluviales/" + idTarifario)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el Item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/TarifariosFluvialesForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(tarifario) {
      return this.$router.push({
        name: "/Hidrocarburos/TarifariosFluvialesForm",
        params: { accion: "Editar", data_edit: tarifario, id: tarifario.id },
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTiposTarifarios();
  },
};
</script>
