<template>
  <div class="login-block">
    <loading v-if="cargando" />
    <div class="row">
      <!--<div class="col-md-6 offset-md-3 text-center">
        <img src="/img/icons/pegaso-icon.png" alt="">
      </div>-->
      <div class="container container-login">
        <div class="row">
          <div class="col-md-12 login-sec">
            <h2 class="text-center">
              <img
                src="/img/logo--fondo-fronteraenergy.png"
                alt=""
                style="width:200px"
              />
            </h2>
            <form class="login-form">
              <div class="form-group">
                <label for="email">Email</label>
                <div class="input-group">
                  <input
                    type="email"
                    class="form-control"
                    v-model="form.email"
                    placeholder="Email"
                    readonly
                    :class="$v.form.email.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="password">Contraseña</label>
                <div class="input-group">
                  <input
                    type="password"
                    class="form-control"
                    v-model="form.password"
                    :class="
                      $v.form.password.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <p class="bg-danger" v-if="!$v.form.password.required">
                  Contraseña es requerido.
                </p>
                <p
                  class="bg-danger"
                  v-if="!$v.form.password.strongPassword"
                >
                  La contraseña no cumple con los parametros de seguridad
                  <ul>
                    <li>Debe tener al menos una letra en mayuscula</li>
                    <li>Debe tener al menos una letra en minuscula</li>
                    <li>Debe tener al menos un numero</li>
                    <li>Debe tener al menos un caracter especial</li>
                    <li>Debe tener al menos 12 caracteres</li>
                  </ul>
                </p>
              </div>
              <div class="form-group">
                <label for="password_confirmation">Confirme Contraseña</label>
                <div class="input-group">
                  <input
                    type="password"
                    class="form-control"
                    v-model="form.password_confirmation"
                    :class="
                      $v.form.password_confirmation.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <p
                  class="bg-danger"
                  v-if="!$v.form.password_confirmation.sameAsPassword"
                >
                  Las contraseñas deben ser iguales.
                </p>
              </div>
              <div class="form-group">
                <router-link to="/Login" class="btn btn-default">
                  Login
                </router-link>

                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#modal-form-validate-2FA"
                  class="btn btn-login float-right"
                  v-if="
                    !$v.form.$invalid
                  "
                @click="enviarToken()"
                >
                  Actualizar clave
                </button>
              </div>
            </form>
            <div class="row mt-3">
              <div class="col-md-12 text-center">
                <img src="/img/logo-sos.png" alt="" style="width: 80px" />
              </div>
            </div>            
          </div>
          <!-- Validación Doble Factor -->
          <div
            class="modal fade"
            id="modal-form-validate-2FA"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                  <h4 class="modal-title text-white">
                    Verificación Ingreso
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                    ref="closeModal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12 mt-3">
                      <div
                        class="alert alert-default-warning border border-warning col-md-12 shadow"
                      >
                        <h5 class="text-center">
                          <i class="icon fas fa-exclamation-triangle"></i>
                          Atención!
                        </h5>
                        <p class="text-center mb-0">
                          Se acabo de enviar el correo con el código de
                          seguridad, el código debe ser númerico.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <template v-for="(input, index) in inputs">
                      <!-- Agregar una clave al input -->
                      <input
                        :key="'input-' + index"
                        type="text"
                        maxlength="1"
                        class="form-control form-control-sm col-sm-1 m-2 pr-0 m-1"
                        v-model="inputs[index]"
                        @input="moveToNext(index)"
                        @keydown="moveToPrevious(index, $event)"
                        @paste="handlePaste($event, index)"
                        :ref="'input-' + index"
                        :class="
                          inputs[index] != '' &&
                          inputs[index] >= 0 &&
                          inputs[index] <= 9
                            ? 'border border-success'
                            : 'border border-danger'
                        "
                      />
                      <!-- Guion con clave única -->
                      <span
                        v-if="index < inputs.length - 1"
                        :key="'dash-' + index"
                        class="align-self-center"
                        >-</span
                      >
                    </template>
                  </div>
                </div>
                <div
                  class="modal-footer justify-content-center"
                  v-show="
                    inputs.every((cod) => cod != '' && cod >= 0 && cod <= 9)
                  "
                >
                  <button
                    type="button"
                    class="btn btn-sm bg-gradient-navy shadow-sm"
                    @click="resetPassword()"
                  >
                    Verificar
                    <i class="fas fa-thumbs-up"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>                
    </div>
  </div>
</template>
<style>
.login-block {
  background: -webkit-linear-gradient(
    to bottom,
    #201747,
    #001871
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #201747,
    #001871
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-login {
  background: #fff;
  border-radius: 10px;
  box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}
.login-sec {
  padding: 50px 30px 20px 30px;
  position: relative;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: #001871;
}
.login-sec .copy-text a {
  color: #201747;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #001871;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #001871;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
</style>
<script>
import axios from "axios";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import Loading from "../components/Loading";
export default {
  components: {
    Loading,
  },
  data: () => ({
    form: {
      email: "",
      password: "",
      token: "",
      password_confirmation: "",
    },
    inputs: Array(7).fill(""),
    code: null,
    cargando: false,
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(12),
        strongPassword(password1) {
          return (
            /[a-z]/.test(password1) && //checks for a-z
            /[A-Z]/.test(password1) && //checks for a-z
            /[0-9]/.test(password1) && //checks for 0-9
            /\W|_/.test(password1) && //checks for special char
            password1.length >= 6
          );
        },
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    resetPassword() {
      let url = "/api/password/reset";
      if (this.form.token == "token") {
        url = "/api/password/expire";
      }
      this.cargando = true;
      this.form.code = this.inputs.join("");
      let data = this.form;      
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.cargando = false;
          this.$refs.closeModal.click();    
          let msg = res.data.user_error ? res.data.user_error :  res.data.success;
          let alert = res.data.user_error ? 'error' : 'success';
          let title = res.data.user_error ? 'ADVERTENCIA..' : 'OK..';
          this.$swal({
            icon: alert,
            title: title,
            text: msg,
          });            
            return this.$router.replace("/Login");          
        })
        .catch((error) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Sucedio un error, vuelva a intentarlo.",
          });
        });
    },
    
    checkToken() {
      if (this.form.token !== "token") {
        this.cargando = true;
        axios
          .get(
            "api/password/reset/checkToken/" +
              this.$route.params.token +
              "/" +
              this.$route.params.email
          )
          .then(() => {
            this.cargando = false;
          })
          .catch(async () => {
            this.cargando = false;
            await this.$swal({
              icon: "error",
              title: "Los datos para recuperar la contraseña no son correctos",
              text: "Por favor vuelve a solicitar la recuperacion",
            });
            return this.$router.replace("/ForgotPassword");
          });
      }
    },

    moveToNext(index) {
      if (this.inputs[index].length === 1 && index < this.inputs.length - 1) {
        this.$refs[`input-${index + 1}`][0].focus();
      }
    },

    moveToPrevious(index, event) {
      if (event.key === "Backspace" && this.inputs[index] === "" && index > 0) {
        setTimeout(() => {
          this.$refs[`input-${index - 1}`][0].focus();
        }, 0);
      }
    },

    handlePaste(event, index) {
      const pastedData = event.clipboardData.getData("text");
      const digits = pastedData.split("").slice(0, this.inputs.length); // Limitar a la longitud de inputs

      // Distribuir los números en cada input
      digits.forEach((digit, i) => {
        if (i < this.inputs.length) {
          this.$set(this.inputs, i, digit);
        }
      });

      // Evitar el comportamiento predeterminado del pegado
      event.preventDefault();
      // Mover el foco al siguiente input
      this.moveToNext(index);
    },

    enviarToken() {
      let me = this;
      me.cargando = true;
      me.form.reset = true;
      axios
        .post("/api/verificacionFa", me.form)
        .then((response) => {
          me.cargando = false;
          me.$swal({
            icon: response.data.estado == 0 ? "success" : "error",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.cargando = false;
        })
        .catch((e) => {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          me.cargando = false;
        });
    },
  },
  mounted() {
    this.form.email = this.$route.params.email;
    this.form.token = this.$route.params.token;
    this.checkToken();
  },
};
</script>