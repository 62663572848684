<template>
  <div class="login-block">
    <loading v-if="cargando" />
    <div class="row">
      <!--<div class="col-md-6 offset-md-3 text-center">
        <img src="/img/icons/pegaso-icon.png" alt="">
      </div>-->
      <div class="container container-login">
        <div class="row">
          <div class="col-md-4 login-sec">
            <h2 class="text-center">
              <img
                src="/img/logo--fondo-fronteraenergy.png"
                alt=""
                style="width: 200px"
              />
            </h2>
            <form class="login-form">
              <div class="input-group mb-3">
                <input
                  type="email"
                  class="form-control"
                  v-model="form.email"
                  placeholder="Email"
                  :class="$v.form.email.$invalid ? 'is-invalid' : 'is-valid'"
                  @input="checkEmail()"
                  autocomplete="off"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input
                  type="password"
                  class="form-control"
                  v-model="form.password"
                  placeholder="Password"
                  :class="$v.form.password.$invalid ? 'is-invalid' : 'is-valid'"
                  autocomplete="off"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>

              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" />
                  <small>Recordarme</small>
                </label>
                <button
                  type="button"
                  class="btn btn-login float-right"
                  v-if="!$v.form.$invalid && password_at && mostrarSave"
                  @click="[validateAndSubmit(), login()]"
                >
                  Ingresar
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#modal-form-validate-2FA"
                  class="btn btn-login float-right"
                  v-if="
                    !$v.form.$invalid && ipDiff && !password_at && mostrarSave
                  "
                  @click="validateAndSubmit()"
                >
                  Ingresar
                </button>
                <button
                  type="button"
                  class="btn btn-login float-right"
                  v-if="
                    !$v.form.$invalid && !ipDiff && !password_at && mostrarSave
                  "
                  @click="[validateAndSubmit(), login()]"
                >
                  Ingresar
                </button>
              </div>

              <div class="input-group mb-3">
                <router-link to="/ForgotPassword"
                  >Deseo recordar mi contraseña</router-link
                >
              </div>
              <!--   <div class="input-group mb-3">
                <router-link to="/PoliticaTratamientoDatos"
                  >Tratamiento de datos personales</router-link
                >
              </div> -->
              <!-- Ingreso Frontera -->
              <div class="row">
                <div class="col-md-12">
                  <button
                    type="button"
                    class="btn bg-frontera-top-left text-white w-100"
                    @click="SignIn"
                  >
                    <i class="fas fa-key"></i> Ingreso Frontera
                  </button>
                </div>

                <!-- <div class="col-md-12 mt-3">
                  <button
                    type="button"
                    class="btn bg-frontera-top-left text-white w-100"
                    data-toggle="modal"
                    data-target="#modal-form-casosSoporte"
                  >
                    <i class="fas fa-rocket"></i> Orion
                  </button>
                </div> -->
              </div>
            </form>
            <div class="row mt-3">
              <div class="col-md-12 text-center">
                <img src="/img/logo-sos.png" alt="" style="width: 80px" />
              </div>
            </div>
          </div>
          <div class="col-md-8 banner-sec">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="3"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="4"
                ></li>
              </ol>
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img
                    class="d-block img-fluid"
                    src="/img/banner_feria_sv1.png"
                    style="height: 460px;"
                    alt="First slide"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block img-fluid"
                    src="/img/banerinicio1.png"
                    alt="First slide"
                    style="height: 460px;"
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <div class="banner-text">
                      <h2>Pegaso Crudo</h2>
                      <p>
                        Pegaso es el proceso de logística de transporte
                        terrestre de Frontera Energy más eficiente, transparente
                        y sostenible que optimiza la nominación y el enturne de
                        los transportadores.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block img-fluid"
                    src="/img/banerinicio2.png"
                    alt="First slide"
                    style="height: 460px;"
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <div class="banner-text">
                      <h2>Pegaso T.E.P</h2>
                      <p>
                        Pegaso es el proceso de logística de transporte
                        terrestre de Frontera Energy más eficiente, transparente
                        y sostenible que optimiza la nominación y el enturne de
                        los transportadores.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block img-fluid"
                    src="/img/banerinicio3.png"
                    alt="First slide"
                    style="height: 460px;"
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <div class="banner-text">
                      <h2>Pegaso T.I.F</h2>
                      <p>
                        Pegaso es el proceso de logística de transporte
                        terrestre de Frontera Energy más eficiente, transparente
                        y sostenible que optimiza la nominación y el enturne de
                        los transportadores.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block img-fluid"
                    src="/img/banerinicio2.png"
                    alt="First slide"
                    style="height: 460px;"
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <div class="banner-text">
                      <h2>Pegaso Carga seca</h2>
                      <p>
                        Pegaso es el proceso de logística de transporte
                        terrestre de Frontera Energy más eficiente, transparente
                        y sostenible que optimiza la nominación y el enturne de
                        los transportadores.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Validación Doble Factor -->
          <div
            class="modal fade"
            id="modal-form-validate-2FA"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                  <h4 class="modal-title text-white">
                    Verificación Ingreso
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                    ref="closeModal"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12 mt-3">
                      <div
                        class="alert alert-default-warning border border-warning col-md-12 shadow"
                      >
                        <h5 class="text-center">
                          <i class="icon fas fa-exclamation-triangle"></i>
                          Atención!
                        </h5>
                        <p class="text-center mb-0">
                          Se acabo de enviar el correo con el código de
                          seguridad, el código debe ser númerico.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <template v-for="(input, index) in inputs">
                      <!-- Agregar una clave al input -->
                      <input
                        :key="'input-' + index"
                        type="text"
                        maxlength="1"
                        class="form-control form-control-sm col-sm-1 m-2 pr-0 m-1"
                        v-model="inputs[index]"
                        @input="moveToNext(index)"
                        @keydown="moveToPrevious(index, $event)"
                        @paste="handlePaste($event, index)"
                        :ref="'input-' + index"
                        :class="
                          inputs[index] != '' &&
                          inputs[index] >= 0 &&
                          inputs[index] <= 9
                            ? 'border border-success'
                            : 'border border-danger'
                        "
                      />
                      <!-- Guion con clave única -->
                      <span
                        v-if="index < inputs.length - 1"
                        :key="'dash-' + index"
                        class="align-self-center"
                        >-</span
                      >
                    </template>
                  </div>
                </div>
                <div
                  class="modal-footer justify-content-center"
                  v-show="
                    inputs.every((cod) => cod != '' && cod >= 0 && cod <= 9)
                  "
                >
                  <button
                    type="button"
                    class="btn btn-sm bg-gradient-navy shadow-sm"
                    @click="login()"
                  >
                    Verificar
                    <i class="fas fa-thumbs-up"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.login-block {
  background: #001871; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #201747,
    #001871
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom right,
    #201747,
    #001871
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-sec {
  border-radius: 0 10px 10px 0;
  padding: 0px;
}
.container-login {
  background: #fff;
  border-radius: 10px;
  box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}
.carousel-inner {
  border-radius: 0 10px 10px 0;
}
.carousel-caption {
  text-align: left;
  left: 5%;
}
.login-sec {
  padding: 50px 30px 10px 30px;
  position: relative;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: #001871;
}
.login-sec .copy-text a {
  color: #201747;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #001871;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #001871;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
.btn-login {
  background: #001871;
  color: #fff;
  font-weight: 600;
}
.banner-text {
  width: 70%;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
}
.banner-text h2 {
  color: #fff;
  font-weight: 600;
}
.banner-text h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
}
.banner-text p {
  color: #fff;
}
</style>

<script>
import { required, email } from "vuelidate/lib/validators";
import Loading from "../components/Loading";
import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    Loading,
  },
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    inputs: Array(7).fill(""),
    code: null,
    cargando: false,
    account: null,
    user: null,
    ipDiff: false,
    password_at: false,
    mostrarSave: false,
    //  {
    //   environment: "login.windows.net",
    //   homeAccountId:"1e250963-6d90-45b7-be49-21c840951f6c.2637d50c-c560-436c-8997-ebe63c9656fd",
    //   idTokenClaims: {},
    //   localAccountId: "1e250963-6d90-45b7-be49-21c840951f6c",
    //   name: "p egaso",
    //   tenantId: "2637d50c-c560-436c-8997-ebe63c9656fd",
    //   username: "pegaso@fronteraenergy.ca",
    // },
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },

  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig
    );
  },

  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.loginAzure(this.account);
  },

  methods: {
    validateAndSubmit() {
      this.inputs = Array(7).fill("");
      if (this.ipDiff && !this.password_at) {
        this.enviarToken();
      }
      this.$recaptcha("login").then((token) => {
        this.form.recaptcha = token;
      });
    },

    /* async orionVehiculos() {
      this.$refs.closeModal.click();
      await this.$router.replace("/SolicitudVehiculo");
    },

    async orionConductores() {
      this.$refs.closeModal.click();
      await this.$router.replace("/SolicitudConductor");
    }, */

    moveToNext(index) {
      if (this.inputs[index].length === 1 && index < this.inputs.length - 1) {
        this.$refs[`input-${index + 1}`][0].focus();
      }
    },

    moveToPrevious(index, event) {
      if (event.key === "Backspace" && this.inputs[index] === "" && index > 0) {
        setTimeout(() => {
          this.$refs[`input-${index - 1}`][0].focus();
        }, 0);
      }
    },

    handlePaste(event, index) {
      const pastedData = event.clipboardData.getData("text");
      const digits = pastedData.split("").slice(0, this.inputs.length); // Limitar a la longitud de inputs

      // Distribuir los números en cada input
      digits.forEach((digit, i) => {
        if (i < this.inputs.length) {
          this.$set(this.inputs, i, digit);
        }
      });

      // Evitar el comportamiento predeterminado del pegado
      event.preventDefault();
      // Mover el foco al siguiente input
      this.moveToNext(index);
    },

    enviarToken() {
      let me = this;
      this.cargando = true;
      axios
        .post("/api/verificacionFa", me.form)
        .then((response) => {
          me.cargando = false;
          if (response.data.estado == 1) {
            this.$refs.closeModal.click();
            this.$swal({
              icon: "error",
              title: "Oops...",
              text:
                "Si los datos introducidos son correctos, se enviará un correo electrónico con un enlace de confirmación.",
            });
            me.cargando = false;
          } else {
            me.$swal({
              icon: "success",
              title: response.data.msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.cargando = false;
          }
          me.cargando = false;
        })
        .catch((e) => {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          me.cargando = false;
        });
    },

    async login() {
      this.cargando = true;
      this.form.code = this.inputs.join("");
      this.inputs = Array(7).fill("");
      await this.$store.dispatch("login", this.form);
      this.cargando = false;

      if (this.$store.getters.isAuthenticated) {
        this.$refs.closeModal.click();
        return this.$router.replace("/");
      } else {
        const errorType = this.$store.getters.getErrors;
        switch (errorType) {
          case "recaptcha":
            this.$swal({
              icon: "error",
              title: "Oops...",
              text:
                "Si los datos introducidos son correctos, se enviará un correo electrónico con un enlace de confirmación.",
            });
            break;
          case "password_updated_at":
            this.$swal({
              icon: "error",
              title: "Oops...",
              text:
                "Ha pasado mucho tiempo sin actualizar la contraseña, por favor cámbiela!",
            });
            return this.$router.push({
              name: "ResetPassword",
              params: {
                token: "token",
                email: this.form.email,
              },
            });
          case "status":
          case "fecha_expire":
          case "autentication_code":
          case "estado_empresa":
            this.$swal({
              icon: "error",
              title: "Oops...",
              text:
                "Si los datos introducidos son correctos, se enviará un correo electrónico con un enlace de confirmación.",
            });
            break;
          case "email":
            this.$refs.closeModal.click();
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: this.$store.getters.getMensajeLogin,
            });
            break;
          default:
            this.$swal({
              icon: "error",
              title: "Oops...",
              text:
                "Si los datos introducidos son correctos, se enviará un correo electrónico con un enlace de confirmación.",
            });
        }
      }
    },

    async loginAzure(cuentaAzure) {
      this.cargando = true;
      await this.$store.dispatch("loginAzure", cuentaAzure);
      if (this.$store.getters.isAuthenticated) {
        this.cargando = false;
        return this.$router.replace("/");
      } else {
        this.cargando = false;
        if (this.$store.getters.getErrors === "status") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text:
              "Si los datos introducidos son correctos, se enviará un correo electrónico con un enlace de confirmación.",
          });
        } else if (this.$store.getters.getErrors === "estado_empresa}") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text:
              "Si los datos introducidos son correctos, se enviará un correo electrónico con un enlace de confirmación.",
          });
        } else if (this.$store.getters.getErrors === "email") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: this.$store.getters.getMensajeLogin,
          });
        } else {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text:
              "Si los datos introducidos son correctos, se enviará un correo electrónico con un enlace de confirmación.",
          });
        }
      }
    },

    async SignIn() {
      await this.$msalInstance
        .loginPopup({})
        .then(() => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          //logueo con azure
          this.loginAzure(this.account);
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },

    async SignOut() {
      await this.$msalInstance
        .logout({})
        .then(() => {
          this.$emitter.emit("logout", "logging out");
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async checkEmail() {
      if (
        this.$v.form.email.$pending === false &&
        this.$v.form.email.$invalid === false
      ) {
        await this.valUser(); // Llamar la validación solo si el email es válido
      }
    },

    async valUser() {
      let ip = null;
      this.ipDiff = false;
      this.password_at = false;
      this.mostrarSave = false;
      await axios
        .get("/api/userValid", {
          params: this.form,
        })
        .then((response) => {
          let user = response.data.dataUser;
          ip = response.data.miIp;

          let fecha_ant = moment()
            .subtract(3, "months")
            .format("YYYY-MM-DD");
          let fecha_actual = new Date(fecha_ant);
          if (user) {
            let password_updated_at = new Date(user.password_updated_at);
            if (fecha_actual > password_updated_at) {
              this.password_at = true;
            }
            if (user.session && ip != user.session.ip_address) {
              this.ipDiff = true;
            }
          }
        });

      this.mostrarSave = true;
    },
  },

  /* computed: {    
  }, */
};
</script>
