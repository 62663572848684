<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Transportes Interno De Fluidos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Funcionarios Frontera</li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_solicitud_excel"
                        v-if="
                          $store.getters.can(
                            'funcionariofrontera.tifSolicitudes.export'
                          )
                        "
                        @click="$refs.SolicitudExportExcel.limpiarModal()"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        v-if="
                          $store.getters.can(
                            'funcionariofrontera.tifSolicitudes.create'
                          )
                        "
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="limpiarFiltros()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <div class="row justify-content-center">
                  <div class="form-group col-md-2">
                    <label># Solicitud</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      v-model="filtros.id"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Nombre Usuario</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.user"
                      @change="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex()"
                    >
                      <option value="">Todos...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                  style="font-size: 0.9em"
                >
                  <thead class="thead bg-dark">
                    <tr class="text-center">
                      <th>#</th>
                      <th class="text-center">Nombre Usuario</th>
                      <th class="text-center">Fecha</th>
                      <th class="text-center">Estado</th>
                      <th class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="Solicitud in solicitudes.data"
                      :key="Solicitud.id"
                    >
                      <td>
                        {{ Solicitud.id }}
                      </td>
                      <td class="text-center">
                        {{ Solicitud.user.name }}
                      </td>
                      <td class="text-center">
                        {{ Solicitud.fecha_solicitud }}
                      </td>
                      <td class="text-center">
                        <span
                          class="shadow-sm"
                          :class="
                            Solicitud.estado == 1
                              ? 'badge bg-info'
                              : Solicitud.estado == 2
                              ? 'badge bg-warning'
                              : Solicitud.estado == 3
                              ? 'badge bg-success'
                              : 'badge bg-danger'
                          "
                        >
                          {{ Solicitud.nEstado }}
                        </span>
                      </td>
                      <td class="text-center">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-sm-2 bg-navy shadow-sm"
                            @click="edit(Solicitud.id)"
                            v-if="
                              $store.getters.can(
                                'funcionariofrontera.tifSolicitudes.edit'
                              )
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn btn-sm-2 btn-danger shadow-sm"
                            @click="destroy(Solicitud.id)"
                            v-if="
                              $store.getters.can(
                                'funcionariofrontera.tifSolicitudes.delete'
                              )
                            "
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudes.total">
                  <p>
                    Mostrando del <b>{{ solicitudes.from }}</b> al
                    <b>{{ solicitudes.to }}</b> de un total:
                    <b>{{ solicitudes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="solicitudes"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SolicitudExportExcel ref="SolicitudExportExcel" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import SolicitudExportExcel from "./SolicitudExportExcel";

export default {
  name: "SolicitudIndex",

  components: {
    pagination,
    Loading,
    SolicitudExportExcel,
  },

  data() {
    return {
      cargando: false,
      filtros: {},
      solicitudes: {},
      listasForms: {
        estados: [],
        empresas: [],
        tipos_vehiculos: [],
        rutas: [],
        tipos_tarifas: [],
        bloques: [],
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/funcionariosFrontera/tifSolicitudes?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.solicitudes = response.data;
          this.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/214").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    limpiarFiltros() {
      this.filtros = {};
      this.getIndex();
    },

    create() {
      return this.$router.push({
        name: "/FuncionariosFrontera/Tif/SolicitudesForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(solicitud_id) {
      return this.$router.push({
        name: "/FuncionariosFrontera/Tif/SolicitudesForm",
        params: { accion: "Editar", id: solicitud_id },
      });
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar esta Solicitud?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/funcionariosFrontera/tifSolicitudes/" + id)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    getEmpresa() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getTipoVh() {
      axios
        .get("api/admin/tiposVehiculos/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.tipos_vehiculos = response.data;
        });
    },

    getRutas() {
      axios.get("/api/tif/rutas/lista").then((response) => {
        this.listasForms.rutas = response.data;
      });
    },

    getProductosLiquidos() {
      axios.get("/api/hidrocarburos/Productos/lista").then((response) => {
        this.listasForms.productosLiquidos = response.data;
      });
    },

    getTurnos() {
      axios
        .get("/api/admin/turnos/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },

    getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipos_tarifas = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },
  },

  beforeMount() {
    this.getEstados();
    this.getEmpresa();
    this.getTipoVh();
    this.getRutas();
    this.getProductosLiquidos();
    this.getTurnos();
    this.getTipoTarifa();
    this.getBloques();
  },

  mounted() {
    this.getIndex();
  },
};
</script>
