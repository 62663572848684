<template>
  <div class="row">
    <div class="col-md-8" id="indicaciones" style="height: 600px;"></div>
    <div class="col-md-4 table-responsive" id="panel" style="height: 600px;">
      <ol id="route-steps"></ol>
    </div>
  </div>
</template>

<script>
const google = window.google;
export default {
  name: "PanelIndicacionesRuta",

  methods: {
    getIndex(route, waypoints) {
      if (route.length > 0) {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        directionsRenderer.setPanel(document.getElementById("panel"));

        const guia_ruta = new google.maps.Map(
          document.getElementById("indicaciones"),
          {
            center: { lat: 4.651371, lng: -74.071509 },
            zoom: 10,
          }
        );
        directionsRenderer.setMap(guia_ruta);

        let request = {
          origin: route[0], // El primer punto del recorrido es el origen
          destination: route[route.length - 1], // El último punto es el destino
          waypoints: waypoints ? JSON.parse(waypoints) : [], // Waypoints entre origen y destino
          travelMode: "DRIVING", // Modo de viaje (DRIVING, WALKING, BICYCLING, TRANSIT)
        };

        directionsService.route(request, (result, status) => {
          if (status === "OK") {
            directionsRenderer.setDirections(result);
          } else {
            this.$swal({
              icon: "error",
              title:
                "No se pudo las generar las indicaciones de ruta: " + status,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
      }
    },
  },
};
</script>
