<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Remolcador</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Remolcador</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-3 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Remolcador</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Documentos"
                    data-toggle="tab"
                    href="#Documentos"
                    v-if="form.id != null"
                    >Documentos</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-9">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="empresa">Empresa</label>
                              <v-select
                                :class="[
                                  $v.form.empresa_id.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                :disabled="opcion == 1 || opcion == 3"
                                v-model="empresa"
                                placeholder="Empresa"
                                label="razon_social"
                                class="form-control form-control-sm p-0"
                                :options="listasForms.empresas"
                                @input="selectEmpresa()"
                              ></v-select>
                              <div
                                class="error"
                                v-if="!$v.form.empresa_id.required"
                              >
                                Seleccione una empresa
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="bandera">Bandera</label>
                              <v-select
                                :class="[
                                  $v.form.bandera.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                :disabled="opcion == 1 || opcion == 3"
                                v-model="bandera"
                                placeholder="bandera"
                                label="nombre"
                                class="form-control form-control-sm p-0"
                                :options="listasForms.paises"
                                @input="selectBandera()"
                              ></v-select>
                              <div
                                class="error"
                                v-if="!$v.form.bandera.required"
                              >
                                Seleccione una bandera
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="nombre">Nombre</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="text"
                                class="form-control form-control-sm"
                                v-model="form.nombre"
                                :class="
                                  $v.form.nombre.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="matricula">Matricula</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="text"
                                class="form-control form-control-sm"
                                id="matricula"
                                v-model="form.matricula"
                                :class="
                                  $v.form.matricula.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="capacidad_toneladas"
                                >Capacidad Toneladas</label
                              >
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="capacidad_toneladas"
                                v-model="form.capacidad_toneladas"
                                :class="
                                  $v.form.capacidad_toneladas.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="eslora">Eslora</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="eslora"
                                v-model="form.eslora"
                                :class="
                                  $v.form.eslora.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label>Manga</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="manga"
                                v-model="form.manga"
                                :class="
                                  $v.form.manga.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="tipo_carroceria"
                                >Fecha Registro</label
                              >
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="date"
                                class="form-control form-control-sm"
                                id="fecha_registro"
                                v-model="form.fecha_registro"
                                :class="
                                  $v.form.fecha_registro.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="linea_negocio">Linea Negocio</label>
                              <input
                                class="form-control form-control-sm"
                                type="text"
                                value="Hidrocarburos"
                                disabled
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="estado">Estado</label>
                              <select
                                class="form-control form-control-sm"
                                id="estado"
                                v-model="form.estado"
                                disabled
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="estado in listasForms.estados"
                                  :key="estado.numeracion"
                                  :value="estado.numeracion"
                                >
                                  {{ estado.descripcion }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label for="imagen">Foto Remolcador</label>
                        <div class="form-group" v-if="!miniLogo">
                          <input
                            :disabled="opcion == 1 || opcion == 3"
                            style="color: red"
                            type="file"
                            class="form-control-file"
                            accept=".JPG, .PNG,image/x-png,image/gif,image/jpeg"
                            @change="obtenerImagen"
                          />
                          <br />
                          <p class="text-center" style="color: red">
                            Fotografía requerida (Max 2MB)
                          </p>
                        </div>
                        <figure class="form-group" v-if="miniLogo">
                          <div class="button-container">
                            <img
                              :src="miniLogo"
                              alt="Logo"
                              width="236"
                              height="125"
                              class="rounded mx-auto d-block"
                            />
                            <a
                              @click="destroyImage()"
                              id="BotonEliminar"
                              href="#"
                              :disabled="opcion == 1 || opcion == 3"
                              >Eliminar <i class="fas fa-window-close"></i
                            ></a>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Documentos" v-if="form.id">
                  <RemolcadorDocumento
                    v-if="form.id != null"
                    ref="RemolcadorDocumento"
                  />
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid && miniLogo"
                    :disabled="opcion == 1"
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import RemolcadorDocumento from "./RemolcadorDocumento";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "RemolcadorForm",
  components: {
    RemolcadorDocumento,
    Loading,
    vSelect,
  },
  data() {
    return {
      opcion: 1,
      cargando: false,
      form: {
        empresa_id: null,
        bandera: null,
        nombre: null,
        matricula: null,
        capacidad_toneladas: null,
        eslora: null,
        manga: null,
        estado: null,
        fecha_registro: null,
        link_imagen: null,
      },
      miniLogo: null,
      archivoValido: false,
      fileImagen: null,
      accion: "",
      metodo: "",
      empresa: [],
      bandera: [],
      listasForms: {
        empresas: [],
        estados: [],
        documentos: [],
        paises: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      odometroInicial: {},
      id: this.$route.params.id,
      //uri_docs: "http://localhost:8000/",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      empresa_id: {
        required,
      },
      bandera: {
        required,
      },
      nombre: {
        required,
      },
      matricula: {
        required,
      },
      capacidad_toneladas: {
        required,
      },
      eslora: {
        required,
      },
      manga: {
        required,
      },
      fecha_registro: {
        required,
      },
    },
  },
  methods: {
    async init() {
      this.accion = this.$route.params.accion;
      this.opcion = this.$route.params.opcion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.miniLogo = this.form.link_imagen
          ? this.uri_docs + this.form.link_imagen
          : null;
        this.empresa = {
          id: this.form.empresa_id,
          razon_social: this.form.empresa.razon_social,
        };
        this.bandera = {
          id: this.form.bandera,
          nombre: this.form.pais ? this.form.pais.nombre : null,
        };
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
        this.form.proveedor = 1;
        this.form.estado = 2;
      }
    },

    async getEmpresas() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    selectEmpresa() {
      this.form.empresa_id = null;
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    selectBandera() {
      this.form.bandera = null;
      if (this.bandera) {
        this.form.bandera = this.bandera.id;
      }
    },

    async getLineasVehiculares() {
      await axios
        .get("/api/admin/vehiculos/marcasLineas", {
          params: {
            estado: 1,
            tipo_combustible: this.form.tipo_combustible || null,
            marca: this.form.marca,
          },
        })
        .then((response) => {
          this.listasForms.lineas_vehiculares = response.data;
        });
    },

    async getEstados() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getPaises() {
      axios.get("/api/admin/paises/lista").then((response) => {
        this.listasForms.paises = response.data;
      });
    },

    // Funciones para carga de imagenes
    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileImagen = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileImagen = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    destroyImage() {
      this.miniLogo = null;
      this.fileImagen = null;
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        let formData = new FormData();
        formData.append("id", this.form.id ? this.form.id : null);
        formData.append("empresa_id", this.form.empresa_id);
        formData.append("nombre", this.form.nombre);
        formData.append("matricula", this.form.matricula);
        formData.append("capacidad_toneladas", this.form.capacidad_toneladas);
        formData.append("eslora", this.form.eslora);
        formData.append("manga", this.form.manga);
        formData.append("bandera", this.form.bandera);
        formData.append("fecha_registro", this.form.fecha_registro);
        formData.append("linea_negocio_id", 2); //lINEA DE NEGOCIO HIDRO
        formData.append("estado", this.form.estado);
        //se valida si se esta cargando una nueva imagen
        if (this.fileImagen) {
          formData.append("link_fotografia", this.fileImagen);
        }
        axios({
          method: "post",
          url: "/api/admin/remolcadores",
          data: formData,
        })
          .then((response) => {
            this.form = response.data;
            this.$swal({
              icon: "success",
              title: "El Remolcador se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    back() {
      return this.$router.replace("/Admin/Remolcadores");
    },
  },
  computed: {
    logo() {
      return this.miniLogo;
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getEmpresas();
    await this.init();
    await this.getEstados();
    await this.getPaises();
    this.cargando = false;
  },
  created() {},
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
