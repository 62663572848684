<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Cabezera de la pagina principal -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Declaración Temporadas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Declaración Temporadas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <!-- Cuerpo de la pagina -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- Cabecera de la tarjeta -->
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <!-- Div que empuja el boton a la izquierda -->
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-gradient-navy"
                        title="Actualizar"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-gradient-primary"
                        data-toggle="modal"
                        data-target="#modal_declaracion"
                        title="Crear"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.declaracionTemporadas.create'
                          )
                        "
                        @click="abrirModal('Crear', null)"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.cabezera de la tarjeta -->
              <div class="card-body p-0">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th class="text-center">
                        Fecha Inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Fecha Final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center">
                        Tipo Temporada
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.temporada"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="temporada in listasForms.temporada"
                            :key="temporada.numeracion"
                            :value="temporada.numeracion"
                          >
                            {{ temporada.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">
                        Sitio Origen
                        <v-select
                          v-model="sitio_origen_filtro"
                          placeholder="Origen"
                          label="nombre"
                          :options="listasForms.sitios"
                          class="form-control form-control-sm p-0"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          @input="selectSitioOrigenFiltro()"
                        ></v-select>
                      </th>
                      <th class="text-center">
                        Sitio Destino
                        <v-select
                          v-model="sitio_destino_filtro"
                          placeholder="Destino"
                          label="nombre"
                          :options="listasForms.sitios"
                          class="form-control form-control-sm p-0"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          @input="selectSitioDestinoFiltro()"
                        ></v-select>
                      </th>
                      <th class="text-center p-4">
                        Contingencia
                      </th>
                      <th class="text-center p-4">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="declaracion in declaracion_temporada.data"
                      :key="declaracion.id"
                    >
                      <!-- <td class="text-center">
                        <span
                          class="badge"
                          :class="item.tipo_ruta == 1 ? 'bg-navy' : 'bg-info'"
                        >
                          {{ item.nTipoRuta }}
                        </span>
                      </td> -->
                      <td class="text-center">
                        {{ declaracion.fecha_inicial }}
                      </td>
                      <td class="text-center">{{ declaracion.fecha_final }}</td>
                      <td class="text-center">{{ declaracion.nTemporada }}</td>
                      <td class="text-center">
                        {{ declaracion.sitio_origen.nombre }}
                      </td>
                      <td class="text-center">
                        {{ declaracion.sitio_destino.nombre }}
                      </td>
                      <td class="text-center">
                        <i
                          v-if="declaracion.contingencia == 0"
                          class="fa fa-times-circle text-danger"
                          data-toggle="tooltip"
                          data-placement="right"
                        >
                        </i>
                        <i
                          v-else
                          class="fa fa-check text-success"
                          data-toggle="tooltip"
                          data-placement="right"
                        >
                        </i>
                      </td>
                      <td class="text-right" style="width: 25px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal_declaracion"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.declaracionTemporadas.edit'
                              )
                            "
                            @click="abrirModal('Editar', declaracion)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            @click="destroy(declaracion.id)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.declaracionTemporadas.delete'
                              )
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer col-md-12">
                <div class="float-left" v-if="declaracion_temporada.total">
                  Mostrando del<b> {{ declaracion_temporada.from }}</b
                  >al <b>{{ declaracion_temporada.to }}</b
                  >de un total: <b>{{ declaracion_temporada.total }}</b
                  >Registros
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="declaracion_temporada"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right mb-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- Modal de agregar y editar -->
      <div
        class="modal fade"
        id="modal_declaracion"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">{{ modal.title }}</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body">
              <div class="row">
                <div class="form-group col-md-12">
                  <label>Sitio Origen</label>
                  <v-select
                    v-model="sitio_origen"
                    placeholder="Origen"
                    label="nombre"
                    :options="listasForms.sitios"
                    class="form-control form-control-sm p-0"
                    :class="[
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                      modal.accion == 'Editar'
                        ? form.sitio_origen_id == null
                          ? 'is-invalid'
                          : 'is-valid'
                        : sitio_origen.length == 0
                        ? 'is-invalid'
                        : 'is-valid',
                    ]"
                    @input="selectSitioOrigen()"
                    :multiple="modal.accion == 'Editar' ? false : true"
                  ></v-select>
                </div>
                <div class="form-group col-md-12">
                  <label>Sitio Destino</label>
                  <v-select
                    v-model="sitio_destino"
                    placeholder="Destino"
                    label="nombre"
                    :options="listasForms.sitios"
                    class="form-control form-control-sm p-0"
                    :class="[
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                      $v.form.sitio_destino_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                    ]"
                    @input="selectSitioDestino()"
                  ></v-select>
                </div>
                <div class="form-group col-md-12">
                  <label>Fecha Inicial</label>
                  <div class="row ml-0">
                    <input
                      type="date"
                      style="font-size: 13px"
                      v-model="form.fecha_ini"
                      class="form-control form-control-sm col-md-6"
                      @input="validaFechas()"
                      :class="
                        $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                    <input
                      type="text"
                      v-model="form.hora_ini"
                      style="font-size: 13px"
                      class="form-control form-control-sm col-md-5 ml-2"
                      @input="validaFechas()"
                      v-mask="{
                        mask: '99:99:99',
                        hourFormat: '24',
                      }"
                      :class="
                        $v.form.hora_ini.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label>Fecha Final</label>
                  <div class="row ml-0">
                    <input
                      type="date"
                      style="font-size: 13px"
                      v-model="form.fecha_fin"
                      class="form-control form-control-sm col-md-6"
                      @input="validaFechas()"
                      :class="
                        $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                    <input
                      type="text"
                      class="form-control form-control-sm col-md-5 ml-2"
                      style="font-size: 13px"
                      v-model="form.hora_fin"
                      @input="validaFechas()"
                      v-mask="{
                        mask: '99:99:99',
                        hourFormat: '24',
                      }"
                      :class="
                        $v.form.hora_fin.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label>Temporada</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.tipo_temporada"
                    :class="
                      $v.form.tipo_temporada.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="temporada in listasForms.temporada"
                      :key="temporada.numeracion"
                      :value="temporada.numeracion"
                    >
                      {{ temporada.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6 p-4">
                  <div class="form-group">
                    <div
                      class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="contingencia"
                        v-model="form.contingencia"
                        :checked="
                          modal.accion == 'Editar'
                            ? form.contingencia > 0
                              ? true
                              : false
                            : ''
                        "
                      />
                      <label class="custom-control-label" for="contingencia"
                        >Contingencia</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-show="
                  modal.accion == 'Crear'
                    ? !$v.form.$invalid && sitio_origen.length > 0
                    : !$v.form.$invalid
                "
                @click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { required, helpers } from "vuelidate/lib/validators";
import vSelect from "vue-select";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "DeclaracionTemporada",
  components: {
    Loading,
    pagination,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      declaracion_temporada: {},
      sitio_origen: [],
      sitio_destino: [],
      sitio_origen_filtro: [],
      sitio_destino_filtro: [],
      modal: {
        title: "",
        accion: "",
      },
      filtros: {
        fecha_ini: null,
        fecha_fin: null,
        sitio_origen_id: null,
        sitio_destino_id: null,
        temporada: null,
      },
      listasForms: {
        sitios: [],
        temporada: [],
      },
      form: {
        id: null,
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        tipo_temporada: null,
        contingencia: false,
        sitio_origen_id: null,
        sitio_destino_id: null,
      },
    };
  },

  validations() {
    if (this.modal.accion == "Crear") {
      return {
        form: {
          fecha_ini: {
            required,
          },
          hora_ini: {
            required,
            timeFormat,
          },
          fecha_fin: {
            required,
          },
          hora_fin: {
            required,
            timeFormat,
          },
          tipo_temporada: {
            required,
          },
          sitio_origen_id: {
            required: false,
          },
          sitio_destino_id: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          fecha_ini: {
            required,
          },
          hora_ini: {
            required,
            timeFormat,
          },
          fecha_fin: {
            required,
          },
          hora_fin: {
            required,
            timeFormat,
          },
          tipo_temporada: {
            required,
          },
          sitio_origen_id: {
            required,
          },
          sitio_destino_id: {
            required,
          },
        },
      };
    }
  },

  methods: {
    // Métodos iniciales
    async getIndex(page = 1) {
      this.cargando = true;
      await axios
        .get("/api/hidrocarburos/declaracionTemporada?page=" + page, {
          params: this.filtros /* parametros que se pasan en la ruta */,
        })
        .then((response) => {
          this.cargando = false;
          this.declaracion_temporada = response.data;
        });
    },

    /* Funciones Listas */
    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    getTipoRuta() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipo_rutas = response.data;
      });
    },

    getTemporada() {
      axios.get("/api/lista/121").then((response) => {
        this.listasForms.temporada = response.data;
      });
    },

    /* Funciones Select Listas */
    selectSitioOrigenFiltro() {
      this.filtros.sitio_origen_id = null;
      if (this.sitio_origen_filtro) {
        this.filtros.sitio_origen_id = this.sitio_origen_filtro.id;
      }
      this.getIndex();
    },

    selectSitioDestinoFiltro() {
      this.filtros.sitio_destino_id = null;
      if (this.sitio_destino_filtro) {
        this.filtros.sitio_destino_id = this.sitio_destino_filtro.id;
      }
      this.getIndex();
    },

    selectSitioOrigen() {
      this.form.sitio_origen_id = null;
      if (this.sitio_origen) {
        if (this.modal.accion == "Editar") {
          this.form.sitio_origen_id = this.sitio_origen.id;
        } else {
          this.form.sitio_origen_id = this.sitio_origen.map((e) => e.id);
        }
      } else {
        this.sitio_origen = [];
      }
    },

    selectSitioDestino() {
      this.form.sitio_destino_id = null;
      if (this.sitio_destino) {
        this.form.sitio_destino_id = this.sitio_destino.id;
      }
    },

    /* Funciones Lógicas */

    // metodo de apertura del modal
    async abrirModal(accion, data = null) {
      this.modal.accion = accion;
      this.modal.title = accion + " Declaración Temporada";
      this.method = "POST";
      await this.resetForm();

      if (data) {
        this.method = "PUT";
        let fecha_hora_ini = data.fecha_inicial.split(" ");
        let fecha_hora_fin = data.fecha_final.split(" ");

        this.sitio_origen = data.sitio_origen;
        this.sitio_destino = data.sitio_destino;

        this.form = {
          id: data.id,
          fecha_ini: fecha_hora_ini[0],
          hora_ini: fecha_hora_ini[1],
          fecha_fin: fecha_hora_fin[0],
          hora_fin: fecha_hora_fin[1],
          tipo_temporada: data.tipo_temporada,
          contingencia: data.contingencia,
          sitio_origen_id: data.sitio_origen_id,
          sitio_destino_id: data.sitio_destino_id,
        };
        //$("#modal_declaracion").modal("show");
      }
    },

    // Métodos para validación de los formularios
    async resetForm() {
      this.sitio_origen = [];
      this.sitio_destino = [];
      this.form = {
        id: null,
        fecha_ini: null,
        hora_ini: null,
        fecha_fin: null,
        hora_fin: null,
        tipo_temporada: null,
        sitio_origen_id: null,
        sitio_destino_id: null,
        contingencia: false,
      };
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    // Métodos de acción de los formularios
    save() {
      if (!this.$v.form.$invalid) {
        this.form.accion = this.modal.accion;
        this.cargando = true;
        axios({
          method: this.method,
          url: "/api/hidrocarburos/declaracionTemporada",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            this.$refs.closeModal.click();
            this.resetForm();
            this.getIndex(); //Lista de nuevo
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(id) {
      this.$swal({
        title: "Está seguro de eliminar este Tipo de Bono?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          axios
            .delete("/api/hidrocarburos/declaracionTemporada/" + id)
            .then(() => {
              this.cargando = false;
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se eliminó la declaración exitosamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getSitios();
    await this.getTemporada();
  },
};
</script>
